import fastdom from "fastdom";

import { getInnerHeight } from "../dom-helpers/inner-size";
import query from "../dom-helpers/query";
import { addToLoop, unLoop } from "../loop";
import "./stacked-content.scss";

export function initStackContentEl(el: Element) {
  const fillEls = query("[data-stacked-content-fill]", el);
  const basisEls = query("[data-stacked-content-basis]", el);

  const render = () => update(el, fillEls, basisEls);
  render();
  addToLoop(render);

  return () => unLoop(render);
}

function update(rootEl: Element, fillEls: Element[], basisEls: Element[]) {
  if (fillEls.length === 0) return;

  fastdom.measure(() => {
    const rootHeight = getInnerHeight(rootEl);
    if (!rootHeight) return;
    const basisElsHeight = basisEls.reduce((acc, el) => acc + el.clientHeight, 0);
    const fillElHeight = (rootHeight - basisElsHeight) / fillEls.length;
    fastdom.mutate(() => {
      fillEls.forEach(el => ((el as HTMLElement).style.maxHeight = `${fillElHeight}px`));
    });
  });
}

export default function initStackedContent() {
  const stackedContents = query("[data-stacked-content]");
  return stackedContents.map(initStackContentEl);
}
