import zenscroll from "zenscroll";

import query from "../dom-helpers/query";
import initActiveNavItemTracker from "./active-navitem-tracker";
import initBurgerNav from "./burger-nav";
import "./navigation.scss";

const NAVITEMS_SELECTOR = "[data-story-nav-item]";

/**
 * When scrolling an element, it's hard to get it exact. This gives us some
 * fudge factor to prevent some the bottom of the previous section post-
 * navigation
 */
const SCROLL_TO_FUDGE_FACTOR = -2;

export function getFixedStoryElementsHeight() {
  const fixedStoryElements = query("[data-fixed-story-element]");
  return fixedStoryElements.reduce((height, el) => height + el.getBoundingClientRect().height, SCROLL_TO_FUDGE_FACTOR);
}

export default function initNavItems(selector = NAVITEMS_SELECTOR) {
  // setup navitem tracking
  const navItems = query(selector) as HTMLElement[];
  initActiveNavItemTracker(navItems);

  initBurgerNav();
  // Add zenscroll for smooth scroll to for # links
  const fixedStoryElementsHeight = getFixedStoryElementsHeight();
  zenscroll.setup(500, fixedStoryElementsHeight);
}
