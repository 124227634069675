import query from "../dom-helpers/query";
import { logEvent, logSocial } from "./index";

export function getDataFromLink(link: HTMLAnchorElement) {
  if (link.hasAttribute("data-social-share")) {
    return {
      type: "social",
      network: link.getAttribute("data-social-share"),
      target: window.location.href,
    };
  } else if (link.hasAttribute("data-story-nav-item")) {
    return {
      type: "nav-link",
      target: link.href,
    };
  } else {
    return {
      type: "link",
      target: link.href,
      element: link,
    };
  }
}

export function onLinkClicked(link: HTMLAnchorElement) {
  const data = getDataFromLink(link);
  if (data.type === "social") {
    logSocial(data.network, "share", data.target);
  } else {
    logEvent(data.type, "click", data.target);
  }
}

export default function initLinkClickTracking() {
  const links = query("a") as HTMLAnchorElement[];
  links.forEach(l => l.addEventListener("click", () => onLinkClicked(l)));
}
