export interface ScrollInfo {
  percentage: number;
  scrollingIn: boolean;
  scrollingOut: boolean;
}

export function getScrollInfo(el: HTMLElement): ScrollInfo {
  const boundingRect = el.getBoundingClientRect();
  const top = -boundingRect.top;
  // scroll length will produce bad results if negative: #2990
  const scrollLength = Math.max(1, boundingRect.height - document.documentElement.clientHeight);
  const percentage = top / scrollLength;

  return {
    percentage,
    scrollingIn: percentage < 0,
    scrollingOut: percentage > 1,
  };
}
