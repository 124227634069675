import queryAll, { query } from "../dom-helpers/query";
import observe, { TriggerPoints, isVisible, unobserve } from "../scrollwatch/index";
import "./lazyload.scss";

export interface Lazyloadable {
  /** Actual picture element to load */
  pictures: HTMLPictureElement[];
  /** Container element to toggle isLoading|isLoaded class on */
  container: HTMLElement;
  /** Element to watch with Scrollwatch to trigger picture loading */
  trigger: HTMLElement;
}

export default function initLazyload() {
  const lazyLoadables = queryAll("[data-lazyload-container][data-lazyload-trigger]") as HTMLElement[];

  lazyLoadables
    .map(container => {
      return {
        pictures: queryAll("[data-lazyload-item] picture", container) as HTMLPictureElement[],
        container,
        trigger: container,
      } as Lazyloadable;
    })
    .forEach(initLazyloadable);
}

export function initLazyloadable({ pictures, container, trigger }: Lazyloadable) {
  let isDestroyed = false;
  const destroy = () => {
    if (!isDestroyed) {
      unobserve(container);
      isDestroyed = true;
    }
  };
  const onVisible = (entry: IntersectionObserverEntry) => {
    if (!isVisible(entry)) return;
    pictures.forEach(picture => loadPicture(picture, () => hideLoadingState(container)));
    destroy();
  };
  observe(trigger, onVisible, {
    triggerPoint: TriggerPoints.Lazyload,
  });
  return destroy;
}

export function loadPicture(picture: HTMLElement, onDone: () => void) {
  const img = query("img", picture) as HTMLElement;
  const sources = queryAll("source", picture) as HTMLElement[];

  if (!img) return;

  img.addEventListener("load", onDone);

  replaceDataAttributes(img);
  sources.forEach(replaceDataAttributes);
}

export function replaceDataAttributes(element: HTMLElement) {
  const ATTRS_TO_REPLACE = {
    "data-src": "src",
    "data-srcset": "srcset",
  };

  Object.entries(ATTRS_TO_REPLACE).forEach(([old, replacement]) => {
    const value = element.getAttribute(old);
    if (!value) return;
    element.removeAttribute(old);
    element.setAttribute(replacement, value);
  });
}

export function hideLoadingState(container: HTMLElement) {
  container.classList.remove("Lazyload--isLoading");
  container.classList.add("Lazyload--isLoaded");
}
