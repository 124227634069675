import { makeAudioSourceListener } from "../audioboss";
import query from "../dom-helpers/query";
import "./soundcloud.scss";

const API_SCRIPT_SRC = "https://w.soundcloud.com/player/api.js";
const isApiScriptLoaded = false;

const CLASSNAME_INITIALIZING = "SoundCloudPlayer--initializing";

declare namespace SC {
  interface Events {
    READY: string;
    PLAY: string;
  }

  class Widget {
    constructor(iframe: HTMLIFrameElement);
    pause(): void;
    play(): void;
    bind(eventName: string, cb: () => void): void;
    public static Events: Events;
  }
}

export function ensureApiScriptLoaded(cb: () => void) {
  if (isApiScriptLoaded) {
    return cb();
  }

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.addEventListener("load", cb);
  script.src = API_SCRIPT_SRC;

  const insertAnchor = document.getElementsByTagName("script")[0];
  insertAnchor.parentNode.insertBefore(script, insertAnchor);
}

// async soundcloud initializer
export function initializePlayer(element: HTMLElement) {
  const src = element.getAttribute("data-src");
  const height = element.getAttribute("data-height");
  const iframe = makeSoundCloudWidget({ src, height });

  iframe.addEventListener("load", () => {
    ensureApiScriptLoaded(() => {
      element.classList.remove(CLASSNAME_INITIALIZING);
      const widget = new SC.Widget(iframe);
      setupAudioBoss(widget, iframe);
    });
  });

  element.appendChild(iframe);
}

export function setupAudioBoss(widget: SC.Widget, element: HTMLElement) {
  const pause = widget.pause.bind(widget);
  widget.bind(SC.Widget.Events.READY, () => {
    widget.bind(SC.Widget.Events.PLAY, makeAudioSourceListener(pause, element));
  });
}

interface Options {
  src: string;
  height: string;
}
interface Attributes {
  [attribute: string]: string;
}
export function makeSoundCloudWidget(options: Options) {
  const iframe = document.createElement("iframe");
  const attributes: Attributes = {
    width: "100%",
    scrolling: "no",
    frameborder: "no",
    src: options.src,
    height: options.height,
  };

  Object.keys(attributes).forEach(key => iframe.setAttribute(key, attributes[key]));

  return iframe;
}

export default function initSoundCloudWidgets() {
  const placeholders = query("[data-soundcloud]");

  window.addEventListener("load", () => {
    setTimeout(() => placeholders.forEach(initializePlayer), 100);
  });
}
