import { IScrollPosition, ScrollEvent, addScrollListener, getElementScrollInfo, removeScrollListener } from "../scroll-track";
import { eventEmitter } from "./emitter";

export interface IBaseElementEvents {
  connected: [];
  disconnected: [];
  enter: [];
  leave: [];
  scroll: [ScrollEvent];
  content: [];
}

function isScrollEvent(type: string): boolean {
  return ["enter", "leave", "scroll"].includes(type);
}

/**
 * Base clase for elements that require scroll-tracking.
 *
 */
export class ScrolledElement extends HTMLElement {
  private internalEvents = eventEmitter<IBaseElementEvents>();
  private scrollListenerCount: number = 0;
  private scrolledParent: HTMLElement;
  private observer = new MutationObserver(() => {
    this.internalEvents.emit("content");
  });
  private scrollListener = (event: ScrollEvent): void => {
    switch (event.type) {
      case "enter":
        this.internalEvents.emit("enter");
        this.internalEvents.emit("scroll", event);
        break;
      case "leave":
        this.internalEvents.emit("leave");
        break;
      case "scroll":
        this.internalEvents.emit("scroll", event);
        break;
    }
  };

  public connectedCallback(): void {
    this.internalEvents.emit("connected");
    if (this.internalEvents.hasListener("content")) {
      this.observer.observe(this, { childList: true, subtree: true });
      if (this.hasChildNodes()) {
        this.internalEvents.emit("content");
      }
    }
    if (this.scrollListenerCount > 0) {
      this.scrolledParent = getScrolledParent(this);
      addScrollListener(this.scrolledParent, this.scrollListener);
      const state = getElementScrollInfo(this.scrolledParent);
      if (state.scrollPercent < 0 || state.scrollPercent > 1) {
        this.internalEvents.emit("leave");
      } else {
        this.internalEvents.emit("enter");
        this.internalEvents.emit("scroll", { element: this.scrolledParent, type: "enter", ...state });
      }
    }
  }

  public getScrollPosition(): IScrollPosition {
    return getElementScrollInfo(this.scrolledParent);
  }

  public disconnectedCallback(): void {
    if (this.scrollListenerCount > 0) {
      removeScrollListener(this.scrolledParent, this.scrollListener);
      this.internalEvents.emit("leave");
    }
    if (this.internalEvents.hasListener("content")) {
      this.observer.disconnect();
    }
    this.internalEvents.emit("disconnected");
  }

  public on<K extends keyof IBaseElementEvents>(event: K, listener: (...args: IBaseElementEvents[K]) => void): void {
    if (this.internalEvents.on(event, listener)) {
      if (isScrollEvent(event)) {
        if (this.isConnected && this.scrollListenerCount === 0) {
          this.scrolledParent = getScrolledParent(this);
          addScrollListener(this.scrolledParent, this.scrollListener);
        }
        this.scrollListenerCount++;
      } else if (event === "content") {
        if (this.isConnected) {
          this.observer.observe(this, { childList: true, subtree: true });
        }
      }
    }
  }

  public off<K extends keyof IBaseElementEvents>(event: K, listener: (...args: IBaseElementEvents[K]) => void): void {
    if (this.internalEvents.off(event, listener)) {
      if (isScrollEvent(event)) {
        this.scrollListenerCount--;
        if (this.isConnected && this.scrollListenerCount === 0) {
          removeScrollListener(this.scrolledParent, this.scrollListener);
        }
      } else if (event === "content") {
        if (this.isConnected) {
          this.observer.disconnect();
        }
      }
    }
  }
}

/**
 * Find the containing non-sticky block element; we want f track _its_ scrolling,not
 * the immediate element.
 * @param element
 * @returns
 */
function getScrolledParent(element: HTMLElement): HTMLElement {
  let node = element;

  while (node.parentElement) {
    const style = getComputedStyle(node);
    if (style.position !== "sticky" && style.display !== "inline") {
      return node;
    }
    node = node.parentElement;
  }
  return node;
}
