/**
 * Return a noop function if ga doesn't exist on the page
 * (Prevent errors for client's who remove GA)
 */

export function gaFallback(...args) {
  // noop
}
export function ga(...args) {
  const _ga = (window as any).ga || gaFallback;
  _ga(...args);
}

export default function initGoogleAnalytics(emitter) {
  emitter.on("logEvent", function (...args) {
    ga("send", "event", ...args);
    ga("shorthand.send", "event", ...args);
  });
  emitter.on("logSocial", (...args) => {
    ga("send", "social", ...args);
    ga("shorthand.send", "social", ...args);
  });
}
