import scrollwatch, { isVisible } from "../scrollwatch";

// Minimum linger seconds required to trigger reporting a linger event
const MIN_LINGER = 5;
const MILLIS_PER_SECOND = 1000;

export function trackElementLingerTime(element: HTMLElement, lingerReport: (time: number) => void) {
  const state = {
    isTracking: false,
    trackingStartAt: 0,
    cumulativeLingerTime: 0,
  };

  // While the element is visible add to the timer
  scrollwatch(element, entry => {
    if (isVisible(entry) && !state.isTracking) {
      state.isTracking = true;
      state.trackingStartAt = Date.now();
    } else if (state.isTracking) {
      state.isTracking = false;
      const lingerTime = Math.round((Date.now() - state.trackingStartAt) / MILLIS_PER_SECOND);

      if (lingerTime < MIN_LINGER) {
        return;
      }

      state.cumulativeLingerTime += lingerTime;
      lingerReport(lingerTime);
    }
  });
}

function getSectionTypeFromClassNames(classNames: string) {
  const SECTION_TYPE_REGEX = /\bTheme-(\w+Section)\b/;
  const results = classNames.match(SECTION_TYPE_REGEX);
  return results !== null ? results[1] : "UnknownTypeSection";
}

export default function initElementLinger(
  elements: HTMLElement[],
  logEvent: (sectionType: string, eventName: string, label: string, value: string | number) => void
) {
  elements.forEach((el, index) => {
    const sectionType = getSectionTypeFromClassNames(el.className);
    const label = index.toString().padStart(3, "0") + " " + el.id;
    const logLingerEvent = (lingerTime: number) => {
      logEvent(sectionType, "linger-seconds", label, lingerTime);
    };

    trackElementLingerTime(el, logLingerEvent);
  });
}
