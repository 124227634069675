import clamp from "clamp";
import fastdom from "fastdom";

import query from "../dom-helpers/query";

// Fade text in/out on scroll
export default function initTextItemFades(section: HTMLElement) {
  const texts = query(".Theme-Layer-BodyText", section.parentElement);

  const viewport = document.getElementById("editor-viewport") ?? document;
  viewport.addEventListener("scroll", onScroll, { passive: true });

  function onScroll(): void {
    texts.forEach(bodyText => {
      fastdom.measure(() => {
        const { top, bottom } = bodyText.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // The threshold from the top and bottom of the screen
        // to calculate the fade from
        const fadingHeight = windowHeight * 0.3;

        const isFadingIn = top < windowHeight && top > windowHeight - fadingHeight;
        const isFadingOut = bottom < fadingHeight && bottom > 0;

        fastdom.mutate(() => {
          if (isFadingIn) {
            const opacity = clamp((windowHeight - top) / fadingHeight, 0, 1);
            bodyText.style.setProperty("opacity", opacity.toString());
          } else if (isFadingOut) {
            const opacity = clamp(bottom / fadingHeight, 0, 1);
            bodyText.style.setProperty("opacity", opacity.toString());
          } else if (bottom < 0 || top > windowHeight) {
            bodyText.style.setProperty("opacity", "0");
          } else {
            bodyText.style.setProperty("opacity", "1");
          }
        });
      });
    });
  }
}
