import { logEvent } from "../analytics";
import { makeAudioSourceListener } from "../audioboss";
import { getDeviceInfo } from "../device-detect";
import query from "../dom-helpers/query";
import { disableStoryScroll, enableStoryScroll } from "../navigation/prevent-story-scroll";
import { Orientation, addOrientationListener, getCurrentOrientation } from "../orientation";
import {
  AutoplayReplayButtonClassName,
  AutoplayReplayButtonType,
  observeAutoplayVideo,
  postAutoplayPauseChecks,
  postAutoplayPlayChecks,
  setupAutoplay,
  setupAutoplayControls,
  unobserveAutoplayVideo,
} from "./foreground-autoplay";
import { getVideoSourceForOrientation, setVideoClass } from "./util";
import "./videoplayer.scss";
import initYoutubeVideos from "./youtube";

const { isSafari } = getDeviceInfo();

const iconSvg = `<svg xmlns="http://www.w3.org/2000/svg">
  <symbol id="video-play-icon" viewBox="0 0 18 18"><path d="M15.562 8.1L3.87.225C3.052-.337 2 .225 2 1.125v15.75c0 .9 1.052 1.462 1.87.9L15.563 9.9c.584-.45.584-1.35 0-1.8z"/></symbol>
  <symbol id="video-error-icon" viewBox="0 0 24 24"><path d="M21.171,15.398l-5.912-9.854C14.483,4.251,13.296,3.511,12,3.511s-2.483,0.74-3.259,2.031l-5.912,9.856  c-0.786,1.309-0.872,2.705-0.235,3.83C3.23,20.354,4.472,21,6,21h12c1.528,0,2.77-0.646,3.406-1.771  C22.043,18.104,21.957,16.708,21.171,15.398z M12,17.549c-0.854,0-1.55-0.695-1.55-1.549c0-0.855,0.695-1.551,1.55-1.551  s1.55,0.696,1.55,1.551C13.55,16.854,12.854,17.549,12,17.549z M13.633,10.125c-0.011,0.031-1.401,3.468-1.401,3.468  c-0.038,0.094-0.13,0.156-0.231,0.156s-0.193-0.062-0.231-0.156l-1.391-3.438C10.289,9.922,10.25,9.712,10.25,9.5  c0-0.965,0.785-1.75,1.75-1.75s1.75,0.785,1.75,1.75C13.75,9.712,13.711,9.922,13.633,10.125z"/></symbol>
 </svg>`;

interface Options {
  iconUrl?: string;
  body?: HTMLElement;
}

function initVideoPlayer(videoplayer: HTMLElement, media: HTMLVideoElement, orientation: Orientation): void {
  /* True if the video has notified us that playback has started, otherwise false */
  let isInitialLoad = true;

  let playing = false;

  /* The overlay that we add during video load; this also serves as a marker
   * that we've initiated (but not completed) a load */
  let loadingDiv: HTMLElement | null = null;

  let errorDiv: HTMLElement | null = null;

  let loadingPoster: HTMLImageElement | null = null;

  let currentOrientation = orientation;

  const {
    dataset: { autoplay, controls, replayButton, id: videoId },
    loop: doesLoop,
  } = media;
  const autoplayIsActive: boolean = autoplay === "true";
  const hasControls: boolean = controls === "true";
  /** used for both autoplay and non-autoplay videos */
  const showControls = autoplayIsActive ? hasControls : media.controls;

  if (autoplayIsActive) {
    setupAutoplay(media, videoId, orientation);
    if (hasControls) {
      setupAutoplayControls(media, videoplayer, replayButton as AutoplayReplayButtonType);
    } else {
      media.controls = false;
      // prevent firstPlay from allowing video to be played once by click
      videoplayer.style.pointerEvents = "none";
    }
    if (!doesLoop) {
      media.addEventListener("ended", () => unobserveAutoplayVideo(videoplayer));
      /* loop attribute is already configured on video elements created in lib/engine files */
    }
    observeAutoplayVideo(videoplayer);
  }

  updatePosterForOrientation(orientation);

  media.controls = false;

  /**
   * Fired when the initial poster load completes to show the video element
   */
  function onReady(): void {
    if (isInitialLoad) {
      isInitialLoad = false;
      setVideoClass("video-stopped", videoplayer);
      videoplayer.removeAttribute("data-lazyload-video");
    }
  }

  /**
   * Update the poster based on the orientation; note we do a preload and then assign the actual
   * poster once it's finished, in order to avoid showing a partially-loaded poster to the user.
   */
  function updatePosterForOrientation(orientation: "landscape" | "portrait"): void {
    const poster = media.getAttribute(`data-${orientation}-poster`);
    if (poster && poster !== media.poster) {
      const img = document.createElement("img");
      function onPosterLoaded(): void {
        if (loadingPoster === img) {
          media.poster = poster;
          onReady();
        }
      }
      img.addEventListener("load", onPosterLoaded);
      img.addEventListener("error", onPosterLoaded);
      img.src = poster;
      loadingPoster = img;
    } else {
      onReady();
    }
  }

  function updateVideoOrientation(orientation: "landscape" | "portrait"): void {
    currentOrientation = orientation;
    if (media.src) {
      const [src] = getVideoSourceForOrientation(media, orientation);
      if (src && src !== media.src) {
        if (playing) {
          media.pause();
          playing = false;
        }
        removeErrorOverlay();
        if (!isInitialLoad) {
          setVideoClass("video-stopped", videoplayer);
        }
        media.src = src;
      }
    }

    updatePosterForOrientation(orientation);
  }

  function addLoadingOverlay(): void {
    if (loadingDiv === null) {
      loadingDiv = document.createElement("div");
      loadingDiv.className = "Lazyload__spinner Theme-Lazyload-Spinner";
      videoplayer.appendChild(loadingDiv);
    }
  }

  function removeLoadingOverlay(): boolean {
    if (loadingDiv !== null) {
      loadingDiv.remove();
      loadingDiv = null;
      return true;
    } else {
      return false;
    }
  }

  function addErrorOverlay(): void {
    if (errorDiv === null) {
      errorDiv = document.createElement("div");
      errorDiv.className = "video-error-container";
      errorDiv.innerHTML =
        '<div class="video-error-block"><svg><use xlink:href="#video-error-icon"/></svg><span>THERE WAS AN ERROR LOADING THIS VIDEO</span></div>';
      videoplayer.appendChild(errorDiv);

      const replayButtonEl = videoplayer.querySelector(`.${AutoplayReplayButtonClassName}`);
      if (replayButtonEl) replayButtonEl.remove();
    }
  }

  function removeErrorOverlay(): boolean {
    if (errorDiv !== null) {
      errorDiv.remove();
      errorDiv = null;
      return true;
    } else {
      return false;
    }
  }

  function manualPlay(): void {
    if (media.readyState === 0) {
      if (loadingDiv === null) {
        addLoadingOverlay();
        setVideoClass("video-loading", videoplayer);
        const [src, mime] = getVideoSourceForOrientation(media, currentOrientation);

        if (isSafari) {
          /* We need to insert a bit of a delay for Safari for WEBM (or probably anything not MP4) */
          function onLoad(): void {
            media.removeEventListener("canplay", onLoad);
            if (mime !== "video/mp4") {
              setTimeout(() => {
                media.play();
              }, 1000);
            } else {
              media.play();
            }
          }
          media.addEventListener("canplay", onLoad);
          media.src = src;
          media.load();
        } else {
          /* Otherwise just let the browser deal with it */
          media.autoplay = true;
          media.src = src;
        }
      }
    } else {
      media.play().catch();
    }
  }

  function firstPlay(event: MouseEvent): void {
    if (!playing) {
      event.preventDefault();
      manualPlay();
      videoplayer.removeEventListener("click", firstPlay);
    }
  }

  media.addEventListener("play", () => {
    if (autoplayIsActive) {
      postAutoplayPlayChecks(media, videoplayer, hasControls);
    }
  });

  media.addEventListener("error", () => {
    /* If we're trying to load, set the error state, otherwise assume it's something else;
     * media errors aren't really very informative unfortunately. */
    if (removeLoadingOverlay()) {
      addErrorOverlay();
      setVideoClass("video-error", videoplayer);
    }
  });
  media.addEventListener("pause", () => {
    setVideoClass("video-paused", videoplayer);
    playing = false;
    postAutoplayPauseChecks(media, videoplayer);
    logEvent("Videos", "pause", media.currentSrc);
  });
  media.addEventListener("playing", () => {
    removeErrorOverlay();
    removeLoadingOverlay();
    setVideoClass("video-playing", videoplayer);
    if (!autoplayIsActive) {
      makeAudioSourceListener(media.pause.bind(media), media)();
    }
    playing = true;
    media.controls = showControls;
    logEvent("Videos", "play", media.currentSrc);
  });
  media.addEventListener("ended", () => {
    setVideoClass("video-paused", videoplayer);
    playing = false;
    logEvent("Videos", "ended", media.currentSrc);
  });
  videoplayer.addEventListener("click", firstPlay);

  const docEl = document.documentElement as HTMLHtmlElement;
  const layer = videoplayer.closest("[class*='Layer--']");
  media.addEventListener("fullscreenchange", () => {
    if (document.fullscreenElement === null) {
      enableStoryScroll(docEl);
      layer.classList.remove("Levels--god");
    } else {
      disableStoryScroll(docEl);
      layer.classList.add("Levels--god");
    }
  });

  addOrientationListener(updateVideoOrientation);
}

export default function initVideoPlayers(options: Options = {}): void {
  if (options.body && !options.iconUrl) {
    const svgParser = new DOMParser();
    const svg = svgParser.parseFromString(iconSvg, "image/svg+xml");

    const svgContainer = document.createElement("div");
    svgContainer.style.display = "none";
    svgContainer.id = "__PLYR_SVGICON_CONTAINER__";
    svgContainer.appendChild(svg.childNodes[0]);
    options.body.appendChild(svgContainer);
  }

  const videoplayers = query("[data-videoplayer]");
  const orientation = getCurrentOrientation();
  videoplayers.forEach(videoplayer => {
    const media = videoplayer.querySelector<HTMLVideoElement>("[data-videoplayer-media]");
    if (media) {
      initVideoPlayer(videoplayer, media, orientation);
    }
  });

  initYoutubeVideos();
}
