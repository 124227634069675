/**
 * Provies a simple wrapper around querySelectorAll but returns a regular JS
 * Array (as opposed to a NodeList which you cannot do much with)
 * @param string selector
 * @param HTMLElement? context
 */

type ContextElement = Element | HTMLElement | Document;

export default function queryAll(selector: string, context: ContextElement = null): HTMLElement[] {
  const queryFrom = getContext(context);
  const nodeList: NodeList = queryFrom.querySelectorAll(selector);
  return Array.from(nodeList) as HTMLElement[];
}

export function query(selector: string, context: ContextElement = null): HTMLElement | false {
  const results = queryAll(selector, context);
  if (results.length === 0) return false;
  return results[0];
}

function getContext(defaultContext: ContextElement): ContextElement {
  if (defaultContext) return defaultContext;
  // If we are embedding with shadow DOM then we provide a __shadowRoot
  if (window["__shadowRoot"]) return window["__shadowRoot"].querySelector("article");

  return document;
}
