export default class FullViewMode {
  /**
   * Measuring element is used to determine the size of 1vh and 1vw unit
   */
  measuringElement: HTMLElement;

  cache = { width: 0, height: 0, top: 0, bottom: 0 };

  constructor(options: { measuringElement?: HTMLElement } = {}) {
    if (options.measuringElement) {
      this.measuringElement = options.measuringElement;
    } else {
      this.ensureMeasuringElement();
    }
    window.addEventListener("resize", this.refresh.bind(this), {
      passive: true,
    });
    this.refresh();
  }

  ensureMeasuringElement() {
    const el = (this.measuringElement = document.createElement("div"));
    el.style.width = "100%";
    el.style.height = "100vh";
    el.style.visibility = "hidden";
    el.style.pointerEvents = "none";
    el.style.opacity = "0";
    el.style.position = "absolute";
    el.style.top = "0";
    el.setAttribute("id", "viewport-height-element");
    document.body.appendChild(el);
  }

  refresh() {
    const rects = this.measuringElement.getBoundingClientRect();
    this.cache.width = rects.width;
    this.cache.height = rects.height;
    this.cache.top = 0;
    this.cache.bottom = 0;
  }

  getCache() {
    return this.cache;
  }
}
