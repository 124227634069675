import queryAll, { query } from "../dom-helpers/query";
import "./footnote.scss";

let footnoteBalloon: HTMLElement | null = null;
let isFootnoteBalloonOpen: boolean = false;

/**
 * Initialise or update any footnotes in the page
 */
export default function initFootnotes(): void {
  initFootnoteBalloon();
  queryAll("[data-footnote]").forEach((highlight: HTMLElement) => initFootnote(highlight));
}

export function initFootnoteBalloon(): void {
  const article = query(".Theme-Story") as HTMLElement;

  // Set up the footnote balloon if we don't already have one
  if (article && (!footnoteBalloon || !query(".Theme-Footnote", article))) {
    footnoteBalloon = document.createElement("div");
    footnoteBalloon.classList.add("Theme-Footnote");
    footnoteBalloon.setAttribute("role", "tooltip");
    footnoteBalloon.setAttribute("aria-live", "polite");
    footnoteBalloon.setAttribute("aria-atomic", "true");
    article.appendChild(footnoteBalloon);
  }
}

/**
 * Work out which element is scrolling
 * @returns the parent that is actually scrolling when you scroll
 */
function getScrollingParent(): HTMLElement | Document {
  return query("#editor-viewport") || document;
}

/**
 * Get the actual top-most bound of the article
 * @returns the height of all elements before the article starts
 */
function getBottomOfHeader(): number {
  // Get header height
  const header = query(".Theme-Header");
  if (!header) return 0;

  const headerHeightRaw = getComputedStyle(header).height;
  const headerHeight = parseInt(headerHeightRaw, 10);

  // Get nav height
  const nav = query(".Navigation__itemList");
  const navHeight = nav && window.innerWidth >= 1100 && nav.childNodes.length ? 36 : 0;

  const navEditor = query(".navbar-editor");
  const navEditorHeight = navEditor ? parseInt(getComputedStyle(navEditor).height, 10) : 0;

  // return total
  return navEditorHeight + headerHeight + navHeight;
}

/**
 * Attach a footnote handler to some highlighted text
 * @param highlight a highlighted span in a piece of Slate HTML
 */
export function initFootnote(highlight: HTMLElement): void {
  highlight.addEventListener("mouseover", () => showFootnote(highlight));
  highlight.addEventListener("touchstart", () => showFootnote(highlight));
  highlight.addEventListener("focus", () => {
    // Wait for a bit because the element might not be visible yet
    setTimeout(() => showFootnote(highlight), 100);
  });
  highlight.addEventListener("keydown", e => {
    switch (e.key) {
      case "Enter":
        if (isFootnoteBalloonOpen) {
          hideFootnote();
        } else {
          showFootnote(highlight);
        }
        break;

      case "ArrowUp":
        if (isFootnoteBalloonOpen) {
          e.preventDefault();
          footnoteBalloon.firstElementChild.scrollBy(0, -20);
        }
        break;

      case "ArrowDown":
        if (isFootnoteBalloonOpen) {
          e.preventDefault();
          footnoteBalloon.firstElementChild.scrollBy(0, 20);
        }
        break;
    }
  });
}

/**
 * Show the footnote balloon
 * @param highlight the highlighted text that has the footnote info
 */
function showFootnote(highlight: HTMLElement): void {
  const highlightBounds = highlight.getBoundingClientRect();

  footnoteBalloon.style.display = "block";
  footnoteBalloon.innerHTML = decodeURIComponent(highlight.getAttribute("data-footnote"));

  // Position the balloon to be above or below the text, depending on how close we are to
  // the viewable boundaries
  const balloonBounds = footnoteBalloon.getBoundingClientRect();
  const isBalloonAbove = highlightBounds.top < getBottomOfHeader() + balloonBounds.height;
  const balloonTop = isBalloonAbove ? highlightBounds.bottom + 5 : highlightBounds.top - balloonBounds.height - 5;

  let balloonLeft = highlightBounds.left + highlightBounds.width / 2;

  // Make sure we aren't off screen
  if (balloonLeft - balloonBounds.width / 2 < 0) {
    balloonLeft = balloonBounds.width / 2 + 2;
  } else if (balloonLeft + balloonBounds.width / 2 > window.innerWidth) {
    balloonLeft = window.innerWidth - balloonBounds.width / 2 - 2;
  }

  // Apply positioning styles
  footnoteBalloon.style.top = `${balloonTop}px`;
  footnoteBalloon.style.left = `${balloonLeft}px`;
  footnoteBalloon.classList.remove("Theme-Position-Top");
  footnoteBalloon.classList.remove("Theme-Position-Bottom");
  footnoteBalloon.classList.add(isBalloonAbove ? "Theme-Position-Top" : "Theme-Position-Bottom");

  // Hide the balloon once something happens
  document.addEventListener("mouseover", onLoseFocus);
  document.addEventListener("touchstart", onLoseFocus);
  getScrollingParent().addEventListener("scroll", onScroll);

  isFootnoteBalloonOpen = true;
}

/**
 * Hide the footnote balloon
 */
function hideFootnote(): void {
  isFootnoteBalloonOpen = false;
  footnoteBalloon.style.display = "";

  document.removeEventListener("mouseover", onLoseFocus);
  document.removeEventListener("touchstart", onLoseFocus);
  getScrollingParent().removeEventListener("scroll", onScroll);
}

/**
 * Hide the footnote balloon on scrolling away
 */
function onScroll(): void {
  hideFootnote();
}

/**
 * Hide the footnote balloon is something else is interacted with
 * @param event
 */
function onLoseFocus(event: MouseEvent): void {
  const target = event.target as HTMLElement;
  if (!target.closest(".Theme-Layer-BodyText-Highlight") && !target.closest(".Theme-Footnote")) {
    hideFootnote();
  }
}
