import StateMachine from "@taoqf/javascript-state-machine";

import { ensureObjectFitOrientationIsCorrect } from "../object-fit";
import once from "../utils/dom-event-once";
import { ScrollInfo, getScrollInfo } from "./scroll-info";

export interface ScrollmationState {
  instances: Map<HTMLElement, ScrollmationInstance>;
  isRunning: boolean;
}

export interface ScrollmationInstance {
  scrollContainer: HTMLElement;
  items: Item[];
  machines: WeakMap<Item, ItemMachine>;
  isRendering: boolean;
}

export interface Item {
  itemEl: HTMLElement;
  data: ItemData;
  index: number;
}

export enum ItemState {
  Ready = "ready",
  Primed = "primed",
  Transitioning = "transitioning",
  Active = "active",
  Lingering = "lingering",
  Completed = "completed",
}

export enum ItemAction {
  Reset = "reset",
  Prime = "prime",
  StartTransition = "startTransition",
  ReverseTransition = "reverseTransition",
  Activate = "activate",
  Linger = "linger",
  Complete = "complete",
}

enum ItemDesiredStates {
  Ready = "ready",
  Active = "active",
  Complete = "complete",
}

interface ItemData {
  start: number;
  end: number;
  showDuringScrollIn: boolean;
  showDuringScrollOut: boolean;
}

type SM = typeof StateMachine;

export interface ItemMachine extends SM {
  new (data: ItemMachineData): ItemMachine;
  isActive(): boolean;
  requestReady(): void;
  requestActive(): void;
  requestComplete(): void;
  item: Item;
}

interface ItemMachineData {
  item: Item;
  classNames: {
    prime: string;
    active: string;
  };
  shouldLinger: (item: Item) => boolean;
  onItemShown?: (item: Item) => void;
}
export const ItemMachine = StateMachine.factory({
  init: ItemState.Ready,

  transitions: [
    // scrolling down
    { name: ItemAction.Prime, from: ItemState.Ready, to: ItemState.Primed },
    {
      name: ItemAction.StartTransition,
      from: ItemState.Primed,
      to: ItemState.Transitioning,
    },
    {
      name: ItemAction.Activate,
      from: ItemState.Transitioning,
      to: ItemState.Active,
    },
    {
      name: ItemAction.Linger,
      from: ItemState.Active,
      to: ItemState.Lingering,
    },
    {
      name: ItemAction.Complete,
      from: [ItemState.Active, ItemState.Lingering],
      to: ItemState.Completed,
    },

    // scrolling back up, repeat in reverse
    {
      name: ItemAction.Activate,
      from: [ItemState.Completed, ItemState.Lingering],
      to: ItemState.Active,
    },
    {
      name: ItemAction.ReverseTransition,
      from: ItemState.Active,
      to: ItemState.Transitioning,
    },
    {
      name: ItemAction.Reset,
      from: ItemState.Transitioning,
      to: ItemState.Ready,
    },
  ],

  methods: {
    onPrimed() {
      const { item, classNames } = this;
      item.itemEl.classList.add(classNames.prime);
      if (this.onItemShown) {
        this.onItemShown(item);
      }
    },

    onStartTransition() {
      const { item, classNames } = this;
      once(item.itemEl, "transitionend", () => this.activate());
      // use rAF to ensure prime and transition aren't written to DOM in the same tick
      requestAnimationFrame(() => item.itemEl.classList.add(classNames.active));

      // IE fails at recalculating object-fit between transitions
      ensureObjectFitOrientationIsCorrect();
    },

    onReverseTransition() {
      const { item, classNames } = this;

      once(item.itemEl, "transitionend", () => this.reset());
      item.itemEl.classList.remove(classNames.active);
    },

    onReset() {
      const { item, classNames } = this;

      item.itemEl.classList.remove(classNames.prime);
      item.itemEl.classList.remove(classNames.active);
    },

    onActive() {
      const { item, classNames } = this;

      [classNames.prime, classNames.active].forEach(c => item.itemEl.classList.add(c));
      if (this.onItemShown) {
        this.onItemShown(item);
      }

      // IE fails at recalculating object-fit between transitions
      ensureObjectFitOrientationIsCorrect();
    },

    onCompleted() {
      const { item, classNames } = this;
      item.itemEl.classList.remove(classNames.prime);
      item.itemEl.classList.remove(classNames.active);
    },

    requestReady() {
      if (this.is(ItemState.Ready)) return;
      if (this.is(ItemState.Active)) {
        this.reverseTransition();
      }
    },

    requestActive() {
      if (this.is(ItemState.Ready)) {
        this.prime();
      }

      if (this.is(ItemState.Primed)) {
        this.startTransition();
      }

      if (this.is(ItemState.Completed) || this.is(ItemState.Lingering)) {
        this.activate();
      }
    },

    requestComplete() {
      const shouldLinger = this.shouldLinger(this.item);
      const canLinger = this.can(ItemAction.Linger);
      const shouldComplete = !shouldLinger;
      const canComplete = this.can(ItemAction.Complete);

      if (shouldLinger && canLinger) {
        this.linger();
      } else if (shouldComplete && canComplete) {
        this.complete();
      }
    },

    isActive() {
      return this.is(ItemState.Active);
    },
  },

  data(data: ItemMachineData) {
    return data;
  },
}) as ItemMachine;

export function renderScrollmation(instance: ScrollmationInstance) {
  if (instance.isRendering) return;
  instance.isRendering = true;

  const scrollInfo = getScrollInfo(instance.scrollContainer);
  instance.items.forEach(item => {
    const desiredState = calculateDesiredState(scrollInfo, item.data);
    if (!desiredState) return;
    const machine = instance.machines.get(item);
    const requestDesiredState = {
      [ItemDesiredStates.Ready]: machine.requestReady,
      [ItemDesiredStates.Active]: machine.requestActive,
      [ItemDesiredStates.Complete]: machine.requestComplete,
    }[desiredState];

    requestDesiredState.call(machine);
  });
  instance.isRendering = false;
}

function calculateDesiredState(scrollInfo: ScrollInfo, itemData: ItemData): ItemDesiredStates {
  const isFirstItem = scrollInfo.scrollingIn && itemData.showDuringScrollIn;
  const isLastItem = scrollInfo.scrollingOut && itemData.showDuringScrollOut;
  const isActive = isInBounds(scrollInfo.percentage, itemData.start, itemData.end);

  if (isFirstItem || isLastItem || isActive) return ItemDesiredStates.Active;
  if (scrollInfo.percentage < itemData.start) return ItemDesiredStates.Ready;
  if (scrollInfo.percentage > itemData.end) return ItemDesiredStates.Complete;
}

function isInBounds(value: number, start: number, end: number) {
  return value >= start && value < end;
}
