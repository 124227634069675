import { toggleClassName } from "../dom-helpers/classy";
import query from "../dom-helpers/query";
import observe, { TriggerPoints, isVisible, unobserve } from "../scrollwatch";
import "./transitions.scss";

interface InitOptions {
  mainSelector: string;
  fadeInClass: string;
}
const defaultOptions: InitOptions = {
  mainSelector: ".TransitionIn--fade",
  fadeInClass: "TransitionIn--fadeIn",
};

export default function initColumnTransitions(options: InitOptions = defaultOptions): void {
  const transitionables = query(options.mainSelector) as HTMLElement[];

  transitionables.forEach(el => {
    watchTransitionedColumn(el, {
      fadeInClass: options.fadeInClass,
    });
  });
}

export function watchTransitionedColumn(element: HTMLElement, options: { fadeInClass: string }): () => void {
  const onEntry = (entry: IntersectionObserverEntry) => {
    // We only want to fade the text in when the section is scrolling into view,
    // so don't fade out once scrolled passed the bottom of the section
    const rootBoundsTop = entry.rootBounds ? entry.rootBounds.top : 0;
    const isScrollingThroughBottom = entry.boundingClientRect.top < rootBoundsTop && !entry.isIntersecting;
    if (isScrollingThroughBottom) return;

    toggleTransitionable(element, options.fadeInClass, isVisible(entry));
  };

  // NOTE: Dirty hack to get around #4329 - for some reason the fadeInClass is removed
  // from a section when you change the colour even though it is still within the viewport
  element.classList.add(options.fadeInClass);

  observe(element, onEntry, { triggerPoint: TriggerPoints.Mostly });
  return () => unobserve(element);
}

export function stopWatchingElement(element: HTMLElement): void {
  unobserve(element);
}

export function toggleTransitionable(element: HTMLElement, fadeInClass: string, visibility: boolean): void {
  toggleClassName(element, fadeInClass, visibility);
}
