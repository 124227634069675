import { checkStatus, checkType } from "../core/error";

interface GalleryItem {
  url: string;
  mediaSrc: string;
  description: string;
  title: string;
  openInNewWindow: boolean;
  isNoFollow: boolean;
}

interface ResponseData {
  items: GalleryItem[];
  title: string;
}

export default function renderGallery(url: string, mode: string, next?: (html: string) => void) {
  fetch(url)
    .then(checkStatus)
    .then(res => checkType(res, "application/json", "Incorrect data for Gallery"))
    .then(response => response.json())
    .then((data: ResponseData) => {
      // Generate complete HTML for Layer (exclude Items that match the current Page).
      const itemlist = data.items
        .filter(item => item.url.replace(/index\.html$/, "") !== window.location.href.replace(/index\.html$/, ""))
        .map(item => {
          const imageClass = mode === "tiles" ? `class="ObjectFit--cover"` : "";
          const img = item.mediaSrc ? `<img alt="" src="${item.mediaSrc}" ${imageClass}>` : "";
          const target = item.openInNewWindow ? 'target="_blank"' : "";
          const rel = item.isNoFollow ? 'rel="nofollow"' : "";
          return `
              <li class="Theme-Layer-Gallery-Item" style="overflow:hidden">
                <a href="${item.url ? item.url : "/"}" ${target} ${rel}>
                  <div class="Theme-Layer-Gallery-Item-Media">
                  ${img}
                  </div>
                  <div class="Theme-Layer-Gallery-Item-Text">
                    <h4 class="Theme-Layer-Gallery-Item-Title">${item.title ? item.title : ""}</h4>
                    <div class="Theme-Layer-Gallery-Item-Description">${item.description ? item.description : ""}</div>
                  </div>
                </a>
              </li>
            `;
        })
        .join("");

      const html = `
          <h3 class="Layout Theme-Layer-Gallery-Heading">${data.title ? data.title : ""}</h3>
          <ul class="Layout Theme-Layer-Gallery-List">${itemlist}</ul>
        `;

      // If invoked by Editor, send html back via callback.
      if (next) {
        return next(html);
      }

      return html;
    })
    .catch(error => {
      console.log("Gallery was unable to parse external data", error);
    });
}
