/**
 * Handy little lib to ensure we've only got one audio source playing at a time.
 * Standard pattern is to listen to a 'play' event and run audioboss (passing in
 * a function that can pause your audio source).
 *
 * const player = new FictitousPlayer(someElement);
 * const pause = player.pause.bind(player);
 * player.addEventListener('play', makeAudioSourceListener(pause, player));
 *
 */

interface CurrentAudioState {
  pause?: () => void;
  instance?: HTMLElement;
}
const currentAudioSource: CurrentAudioState = {};

/**
 * @param pauseFn {function} - Tells audioboss how to pause the playing thing
 * @param instance {any} - Some kind of unique identifier for the currently playing thing
 */
export function makeAudioSourceListener(pauseFn: () => void, instance: HTMLElement) {
  return (): void => {
    if (currentAudioSource.pause && currentAudioSource.instance !== instance) {
      currentAudioSource.pause();
    }

    currentAudioSource.pause = () => {
      delete currentAudioSource.pause;
      pauseFn();
    };
    currentAudioSource.instance = instance;
  };
}
