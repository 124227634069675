const setTimeout = window.setTimeout;
const clearTimeout = window.clearTimeout;

// FALLBACK CODE FOR IE9
/**
 * Timing state
 */
let lastTime = 0;

/**
 * Fake raf by using setTimeout
 */
const rafFallback = fn => {
  const currTime = Date.now();
  const timeToCall = Math.max(0, 16 - (currTime - lastTime));
  const id = setTimeout(() => fn(currTime + timeToCall), timeToCall);
  lastTime = currTime + timeToCall;
  return id;
};

/**
 * Cancel fallback that will clear the setTimeout
 */
const cancelRafFallback = id => clearTimeout(id);

export const requestAnimationFrame = window.requestAnimationFrame ? window.requestAnimationFrame.bind(window) : rafFallback;
export const cancelAnimationFrame = window.requestAnimationFrame ? window.cancelAnimationFrame.bind(window) : cancelRafFallback;
