import fastdom from "fastdom";
import "objectFitPolyfill";

import { getDeviceInfo } from "../device-detect";
import DisplayContainer from "../display-container";
import queryAll from "../dom-helpers/query";
import "./object-fit.scss";

export function doYouEvenFitObjects() {
  if (!window) return false;
  const firstObjectFitElement = document.querySelector(".ObjectFit--cover");
  if (!firstObjectFitElement) return false;

  const stylez = window.getComputedStyle(firstObjectFitElement);
  const soFitMuchCross = stylez.objectFit;
  const isEdge = getDeviceInfo().isEdge;
  const hasObjectFit = soFitMuchCross ? /^(contain|cover|fill)$/.test(soFitMuchCross) : false;

  // Edge doesn't properly support object-fit, so we force the polyfill for it
  return hasObjectFit && !isEdge;
}

/**
 * Ensure the focal points for an object-fit element match the orientation
 */
export function ensureObjectFitOrientationIsCorrect() {
  fastdom.measure(() => {
    const orientation = DisplayContainer.getOrientation();

    fastdom.mutate(() => {
      const elements = queryAll(".ObjectFit--cover");

      elements.forEach((element: HTMLElement) => {
        element.dataset.objectFit = "cover";

        // img tags get their focal point info from their parent picture tag
        const elementWithValues = element.tagName === "IMG" ? element.parentElement : element;

        if (elementWithValues.hasAttribute(`data-${orientation}-focal`)) {
          element.dataset.objectPosition = elementWithValues.dataset[`${orientation}Focal`];
        }
      });

      applyObjectFit(elements);
    });
  });
}

/**
 * Apply the object-fit polyfill and then correct for split sections in IE
 * @param elements
 */
export function applyObjectFit(elements: Array<HTMLElement>): void {
  (window as any).objectFitPolyfill(elements);
  fixIEObjectFit(elements);
}

export function fixIEObjectFit(elements: Array<HTMLElement>): void {
  const { isIE, isEdge } = getDeviceInfo();
  if (!isIE && !isEdge) return;

  // IE will end up with split section fixed media being slightly offset due to it being calculated
  // as though it were absolute
  elements.forEach((element: HTMLElement) => fixIEObjectFitForElement(element));
}

export function fixIEObjectFitForElement(element: HTMLElement): void {
  const { isIE, isEdge } = getDeviceInfo();
  if (!isIE && !isEdge) return;

  if (element.className.indexOf("FullSize__fixedChild") === -1) return;

  fastdom.measure(() => {
    // DisplayContainer facade doesn't account for the full window size
    const orientation = window.innerWidth > window.innerHeight ? "landscape" : "portrait";

    // Make sure its a split section
    const split = getSplitSectionType(element);
    if (split === "none") return;

    const parentWidth: number = parseFloat(window.getComputedStyle(element.parentElement).getPropertyValue("width"));

    if (isEdge) {
      element.style.setProperty("width", `${orientation === "landscape" ? parentWidth + "px" : "100%"}`);
      return;
    }

    if (orientation === "portrait") {
      element.style.removeProperty("transform");
      return;
    }

    // The readjustments are based on whether or not the media is portrait or landscape sized
    const mediaDimensions =
      "play" in element
        ? {
            width: (element as HTMLVideoElement).videoWidth,
            height: (element as HTMLVideoElement).videoHeight,
          }
        : {
            width: (element as HTMLImageElement).naturalWidth,
            height: (element as HTMLImageElement).naturalHeight,
          };

    if (mediaDimensions.width < 1) {
      setTimeout(() => fixIEObjectFitForElement(element), 100);
      return;
    }

    const isLandscapeMedia = mediaDimensions.width > mediaDimensions.height;

    fastdom.mutate(() => {
      // IE (Edge early exited up further)
      if (isLandscapeMedia) {
        // Landscape

        if (split === "media-left") {
          if (element.style.getPropertyValue("left") === "50%") {
            // Focal center
            element.style.setProperty("transform", `translateX(-${parentWidth * 0.5}px)`);
          } else if (!["", "0px"].includes(element.style.getPropertyValue("margin-left"))) {
            // Focal left
            element.style.setProperty("transform", `translateX(-${parentWidth * 0.25}px)`);
          } else {
            // Focal right
            element.style.setProperty("transform", `translateX(-${parentWidth * 0.75}px)`);
          }
        } else {
          if (element.style.getPropertyValue("left") === "50%") {
            // Focal center
            element.style.setProperty("transform", `translateX(${parentWidth * 0.5}px)`);
          } else if (!["", "0px"].includes(element.style.getPropertyValue("margin-left"))) {
            // Focal left
            element.style.setProperty("transform", `translateX(${parentWidth * 0.75}px)`);
          } else {
            // Focal right
            element.style.setProperty("transform", `translateX(${parentWidth * 0.25}px)`);
          }
        }
      } else {
        // Portrait

        const [x, y] = element.dataset["object-position"].split(" ").map(p => parseInt(p, 10));

        const hasMarginLeft = element.style.getPropertyValue("margin-left") && element.style.getPropertyValue("margin-left") !== "0px";
        const hasMarginRight =
          element.style.getPropertyValue("margin-right") && element.style.getPropertyValue("margin-right") !== "0px";

        if (split === "media-right") {
          if (x === 50) {
            // focal middle
            element.style.setProperty("transform", `scale(0.5) translate(${parentWidth}px, 0%)`);
          } else if (y < 50) {
            // focal top
            element.style.setProperty("transform", `scale(0.5) translate(${parentWidth}px, -${50 - y}%)`);
          } else {
            // focal bottom
            element.style.setProperty("transform", `scale(0.5) translate(${parentWidth}px, ${y - 50}%)`);
          }

          // For some breakpoints we need to readjust based on left/right margins
          if (hasMarginLeft) {
            element.style.setProperty(
              "transform",
              `translateX(${parentWidth - Math.abs(parseFloat(element.style.getPropertyValue("margin-left")))}px)`
            );
            return;
          }
          if (hasMarginRight) {
            element.style.setProperty(
              "transform",
              `translateX(${Math.abs(parseFloat(element.style.getPropertyValue("margin-right")))}px)`
            );
            return;
          }
        } else {
          // media-left

          if (y === 50) {
            // focal middle
            element.style.setProperty("transform", `scale(0.5) translate(-${parentWidth}px, 0%)`);
          } else if (y < 50) {
            // focal top
            element.style.setProperty("transform", `scale(0.5) translate(-${parentWidth}px, -${50 - y}%)`);
          } else {
            // focal bottom
            element.style.setProperty("transform", `scale(0.5) translate(-${parentWidth}px, ${y - 50}%)`);
          }

          // For some breakpoints we need to readjust based on left/right margins
          if (hasMarginLeft) {
            element.style.setProperty(
              "transform",
              `translateX(-${Math.abs(parseFloat(element.style.getPropertyValue("margin-left")))}px)`
            );
            return;
          }
          if (hasMarginRight) {
            element.style.setProperty(
              "transform",
              `translateX(-${parentWidth - Math.abs(parseFloat(element.style.getPropertyValue("margin-right")))}px)`
            );
            return;
          }
        }
      }
    });
  });
}

/**
 * Check back up the tree to see if we are in a SplitLayout and, if so, what kind
 * @param element
 */
function getSplitSectionType(element: HTMLElement): "media-left" | "media-right" | "none" {
  if (element.id?.indexOf("section-") === 0) {
    if (element.className.indexOf("SplitLayout--rowReverse") > -1) {
      return "media-left";
    } else if (element.className.indexOf("SplitLayout") > -1) {
      return "media-right";
    } else {
      return "none";
    }
  }

  return getSplitSectionType(element.parentElement);
}

/**
 * Kick off object-fit and object-position handling
 */
export default function initObjectFit() {
  window.addEventListener("resize", () => ensureObjectFitOrientationIsCorrect(), { passive: true });
  ensureObjectFitOrientationIsCorrect();
}
