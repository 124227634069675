import fastdom from "fastdom";

import DisplayContainer from "../display-container";

const ACTIVE_NAVITEM_CLASS: string = "Theme-ActiveNavigationBarItem";

interface TargettedItem {
  target: HTMLElement;
  navItem: HTMLElement;
}
export function getTargettedNavItems(navItems: HTMLElement[]) {
  return navItems.reduce((targets, navItem) => {
    const href = navItem.getAttribute("href");
    if (href.indexOf("#") !== 0) {
      return targets;
    }

    const htmlId = href.replace(/^#/, "");
    try {
      const target = document.getElementById(htmlId);
      if (!target) {
        console.warn(`navigation: Couldn't locate target via nav item id '${htmlId}'.`);
      }
      targets.push({ target, navItem });
    } catch (err) {
      console.warn(`navigation: Bad target via nav item selector ${htmlId}.`);
    }

    return targets;
  }, [] as TargettedItem[]);
}

export function deactivateNavItems(navItems: HTMLElement[]) {
  navItems.forEach(deactivateNavItem);
}

export function deactivateNavItem(navItem: HTMLElement) {
  navItem.parentElement.classList.remove(ACTIVE_NAVITEM_CLASS);
}

export function activateNavItem(navItem: HTMLElement) {
  navItem.parentElement.classList.add(ACTIVE_NAVITEM_CLASS);
}

function calculateMostVisibleElement(targettedNavItems: TargettedItem[]) {
  const THRESHOLD = DisplayContainer.getHeight() / 2;
  return targettedNavItems.find(item => {
    const rect = item.target.getBoundingClientRect();
    const isTitleSectionTarget = item.target.className.match(/\bTheme-TitleSection\b/) !== null;

    return rect.top < THRESHOLD && (isTitleSectionTarget || rect.bottom > THRESHOLD);
  });
}

interface State {
  lastMostVisible: boolean | TargettedItem;
}
const state: State = { lastMostVisible: false };
function render(targettedNavItems: TargettedItem[]) {
  fastdom.measure(() => {
    // reverse due to eager TitleSecton matching (caused by different DOM structure)
    const mostVisible = calculateMostVisibleElement(targettedNavItems.slice().reverse());
    if (state.lastMostVisible === mostVisible) return;

    fastdom.mutate(() => {
      deactivateNavItems(targettedNavItems.map(i => i.navItem));
      if (mostVisible) {
        activateNavItem(mostVisible.navItem);
      }
      state.lastMostVisible = mostVisible;
    });
  });
}

export default function initNavItemTracker(navItems: HTMLElement[]) {
  const targettedNavItems = getTargettedNavItems(navItems);
  if (targettedNavItems.length === 0) return;
  const update = () => render(targettedNavItems);

  ["scroll", "resize"].forEach(eventName => window.addEventListener(eventName, update));

  // catch scrolling changes that aren't caused by actual scrolling (e.g. CSS
  // changes, loading glitches, something manually updating scrollpos)
  setTimeout(update, 1000);

  // force an initial render immediately
  update();
}
