import fastdom from "fastdom";

import { getInnerWidth } from "../dom-helpers/inner-size";

const elementResizeDetectorMaker = require("element-resize-detector");

interface ResizableElement {
  element: HTMLElement;
  onResize: () => void;
}

interface State {
  elements: ResizableElement[];
}

const state: State = {
  elements: [],
};

const elementResizeDetector = elementResizeDetectorMaker();

export function addParentWidthElement(element: HTMLElement) {
  const { elements } = state;
  if (elements.findIndex(f => f.element === element) > -1) {
    return;
  }

  const resizableElement = {
    element,
    onResize() {
      updateElement(element);
    },
  };

  elements.push(resizableElement);
  updateElement(element, true);
  elementResizeDetector.listenTo(resizableElement.element.parentElement, resizableElement.onResize);
  initParentWidth();
}

export function removeParentWidthElement(element: HTMLElement) {
  const { elements } = state;
  const index = elements.findIndex(f => f.element === element);
  if (index === -1) {
    return;
  }

  let resizableElement = elements[index];
  elementResizeDetector.removeListener(resizableElement.element.parentElement, resizableElement.onResize);

  elements.splice(index, 1);
}

export function updateParentWidths() {
  const { elements } = state;
  elements.forEach(el => updateElement(el.element));
}

export function updateElement(element: HTMLElement, force = false) {
  fastdom.measure(() => {
    const parent = element.parentNode as HTMLElement;
    const parentWidth = parent ? getInnerWidth(parent) : -1;
    const elWidth = element.clientWidth;

    if (parentWidth !== elWidth || force) {
      fastdom.mutate(() => {
        element.style.width = `${parentWidth}px`;
      });
    }
  });
}

export default function initParentWidth() {
  const els = document.getElementsByClassName("ParentWidth");
  Array.prototype.forEach.call(els, addParentWidthElement);

  updateParentWidths();
}
