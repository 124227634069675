import { Orientation } from "../orientation";
import { getVideoSourceForOrientation, setVideoClass } from "./util";

export const AutoplayReplayButtonClassName = "AutoplayReplayButton";
const AutoplayReplayButtonText = {
  unmuteAndReplay: "Unmute & Replay Video",
  replay: "Replay Video",
};
export type AutoplayReplayButtonType = keyof typeof AutoplayReplayButtonText | undefined;

type ForegroundAutoplayTracking = {
  nowPlaying: string[];
  nowPaused: string[];
  volumeHasBeenInteractedWith: boolean;
  firstVideoWithVolumeInteraction: null | string;
  scrollListenerIsActive: boolean;
  videoClosestToCenterY: null | string;
};

/** Various flags and tracking for foreground autoplay videos with controls active */
const autoplayTracking: ForegroundAutoplayTracking = {
  /** IDs of videos that are currently playing in viewport */
  nowPlaying: [],
  /**
   * IDs of videos that are currently paused by a means other than being scrolled
   * out of view, i.e. the video has been paused by the user or by the video ending.
   * Note that a looping video doesn't trigger an ended event.
   */
  nowPaused: [],
  /**
   * For determining whether we need to concern ourselves with checking if
   * multiple videos are playing in viewport
   */
  volumeHasBeenInteractedWith: false,
  /**
   * ID of the first video to have its volume changed, which is used to ensure that when
   * there are multiple videos in view, and the user selects our replay video button or
   * turns the volume up on a foreground video (which also effectively selects and
   * hides the replay buttons) that isn't closest to the center, that it unmutes
   * the volume for the video they touched, as opposed to the volume of the video that's
   * closest to center. After the ID has been set and the volume is changed for a new video,
   * this gets permanently set back to null
   */
  firstVideoWithVolumeInteraction: null,
  /**
   * Set to true when multiple videos are playing within the viewport and
   * we're checking for the center-most video on scroll
   */
  scrollListenerIsActive: false,
  /**
   * The ID of the video that is currently closest to the vertical center
   * of the viewport, when multiple videos are in the viewport
   */
  videoClosestToCenterY: null,
};

const io = new IntersectionObserver(handleAutoplayIntersection, {
  root: null,
  rootMargin: "0px",
  threshold: 0.1,
});
export function unobserveAutoplayVideo(videoplayer: HTMLElement): void {
  io.unobserve(videoplayer);
}
export function observeAutoplayVideo(videoplayer: HTMLElement): void {
  io.observe(videoplayer);
}

export function setupAutoplay(media: HTMLVideoElement, videoId: string, orientation: Orientation): void {
  media.dataset.autoplay = "true";
  media.dataset.id = videoId;
  media.preload = "auto";
  media.defaultMuted = true;
  media.muted = true;
  media.classList.remove("video-play");
  const [src] = getVideoSourceForOrientation(media, orientation);
  media.src = src;
  media.load();
}

export function setupAutoplayControls(
  media: HTMLVideoElement,
  videoplayer: HTMLElement,
  replayButton: AutoplayReplayButtonType
): void {
  media.controls = true;
  media.addEventListener("volumechange", initialAutoplayVolumeChangeChecks);

  if (replayButton) {
    const replayButtonEl = document.createElement("button");
    replayButtonEl.textContent = AutoplayReplayButtonText[replayButton];
    replayButtonEl.classList.add(AutoplayReplayButtonClassName);
    videoplayer.append(replayButtonEl);
    videoplayer.addEventListener("click", ({ target }) => {
      if ((target as HTMLElement).classList.contains(AutoplayReplayButtonClassName)) {
        handleAutoplayReplayButtonSelection(media);
      }
    });
  }
}

function handleAutoplayReplayButtonSelection(media: HTMLVideoElement): void {
  media.currentTime = 0;
  /* Unmuting removes the replay button */
  media.muted = false;
  media.play();
}

function initialAutoplayVolumeChangeChecks({ target }): void {
  if (!autoplayTracking.volumeHasBeenInteractedWith) {
    autoplayTracking.volumeHasBeenInteractedWith = true;
    autoplayTracking.firstVideoWithVolumeInteraction = target.dataset.id;
    document.querySelectorAll(`.${AutoplayReplayButtonClassName}`).forEach(btn => btn.remove());
    const videos = document.querySelectorAll("video[data-controls=true]");
    unmuteAll(videos);
  } else if (target.dataset.id !== autoplayTracking.firstVideoWithVolumeInteraction) {
    autoplayTracking.firstVideoWithVolumeInteraction = null;
    /** foreground videos with controls */
    const videos = document.querySelectorAll("video[data-controls=true]");
    videos.forEach(video => video.removeEventListener("volumechange", initialAutoplayVolumeChangeChecks));
  }
  checkTrackingOfMultipleVideos();
}

function handleAutoplayIntersection(entries: IntersectionObserverEntry[]): void {
  for (const entry of entries) {
    const { target, isIntersecting } = entry;
    const media = target.querySelector("video");
    const videoId = media.dataset.id;
    const videoplayer = media.closest("[data-videoplayer]") as HTMLElement;
    if (isIntersecting) {
      media
        .play()
        .then(() => setVideoClass("video-playing", videoplayer))
        .catch(err => {
          const replayButtonEl = videoplayer.querySelector(`.${AutoplayReplayButtonClassName}`);
          if (replayButtonEl) replayButtonEl.remove();
          console.error("Playback of video failed: ", err);
        });
    } else {
      if (autoplayTracking.nowPlaying.includes(videoId)) {
        /** Removing the outgoing autoplay video from this list before the video is paused
         * allows us to distinguish between a video paused by leaving an intersection,
         * as opposed to the user pausing it or it "pausing" via the video ending. The
         * distinction is needed because we want to remove a video from autoplay
         * consideration in the latter two scenarios */
        autoplayTracking.nowPlaying = autoplayTracking.nowPlaying.filter(id => id !== videoId);
      }
      media.pause();
    }
  }
}

export function postAutoplayPlayChecks(media: HTMLVideoElement, videoplayer: HTMLElement, videoHasControls: boolean): void {
  const videoId = media.dataset.id;
  if (videoHasControls) {
    autoplayTracking.nowPlaying.push(videoId);
    checkTrackingOfMultipleVideos();

    if (autoplayTracking.nowPaused.includes(videoId)) {
      observeAutoplayVideo(videoplayer);
      autoplayTracking.nowPaused = autoplayTracking.nowPaused.filter(id => id !== videoId);
    }
  }
}

export function postAutoplayPauseChecks(media: HTMLVideoElement, videoplayer: HTMLElement): void {
  const videoId = media.dataset.id;
  if (autoplayTracking.nowPlaying.includes(videoId)) {
    unobserveAutoplayVideo(videoplayer);
    autoplayTracking.nowPaused.push(videoId);
    autoplayTracking.nowPlaying = autoplayTracking.nowPlaying.filter(id => id !== videoId);
  }
  checkTrackingOfMultipleVideos();
}

/**
 * Logic pertaining to tracking when multiple foreground autoplay videos with
 * controls are in view at once, and muting any of said videos that are not
 * the currently the focused (i.e. usually center-most) video.
 */
function checkTrackingOfMultipleVideos(): void {
  if (!autoplayTracking.volumeHasBeenInteractedWith) return;

  if (autoplayTracking.nowPlaying.length > 1) {
    if (!autoplayTracking.scrollListenerIsActive) {
      document.addEventListener("scroll", muteAllExceptCenterVideo);
      autoplayTracking.scrollListenerIsActive = true;

      if (autoplayTracking.firstVideoWithVolumeInteraction) {
        /**
         * The video to be unmuted is the first one that
         * had it's volume turned up, as opposed to the one
         * closest to the center of the screen
         * */
        const videoToUnmute = document.querySelector(
          `[data-id="${autoplayTracking.firstVideoWithVolumeInteraction}"]`
        ) as HTMLVideoElement;
        muteAllExcept(videoToUnmute);
      } else {
        muteAllExceptCenterVideo();
      }
    }
  } else {
    document.removeEventListener("scroll", muteAllExceptCenterVideo);
    autoplayTracking.scrollListenerIsActive = false;
    autoplayTracking.videoClosestToCenterY = null;
  }
}

function muteAllExcept(video: HTMLVideoElement): void {
  unmute(video);
  for (const id of autoplayTracking.nowPlaying) {
    if (id !== video.dataset.id) {
      const videoToMute = document.querySelector(`[data-id="${id}"]`) as HTMLVideoElement;
      videoToMute.muted = true;
    }
  }
}

function unmuteAll(videos): void {
  videos.forEach(video => unmute(video));
}

function unmute(video: HTMLVideoElement): void {
  video.muted = false;
}

/**
 * When multiple foreground autoplay videos with controls are in view, each time the user
 * scrolls, find the video closest to vertical center of the viewport to unmute it
 * and then mute all the others
 */
function muteAllExceptCenterVideo(): void {
  let currentVideoClosestToCenterY = null;
  /** relative distance to center of viewport */
  let currentVideoClosestToCenterYDistance = null;
  for (let i = 0; i < autoplayTracking.nowPlaying.length; i++) {
    const video: HTMLElement = document.querySelector(`[data-id="${autoplayTracking.nowPlaying[i]}"]`);
    const videoRelPos = getDifferenceInPositionFromCenter(video);
    if (currentVideoClosestToCenterY === null || videoRelPos <= currentVideoClosestToCenterYDistance) {
      currentVideoClosestToCenterY = autoplayTracking.nowPlaying[i];
      currentVideoClosestToCenterYDistance = videoRelPos;
    }
  }

  if (autoplayTracking.videoClosestToCenterY !== currentVideoClosestToCenterY) {
    autoplayTracking.videoClosestToCenterY = currentVideoClosestToCenterY;
    const centerVideo: HTMLVideoElement = document.querySelector(`[data-id="${autoplayTracking.videoClosestToCenterY}"]`);
    muteAllExcept(centerVideo);
  }
}

function getDifferenceInPositionFromCenter(el: HTMLElement): number {
  const viewportCenterPositionY = getViewportCenterPositionY();
  const elMiddlePos = getMiddlePosition(el);
  return Math.abs(viewportCenterPositionY - elMiddlePos);
}

function getMiddlePosition(el: HTMLElement): number {
  const { top, bottom } = el.getBoundingClientRect();
  return (top + bottom) / 2;
}

function getViewportCenterPositionY(): number {
  return window.innerHeight / 2;
}
