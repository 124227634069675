import { getDeviceInfo } from "../device-detect";

// Supports legacy IE and Edge MouseWheelEvent by extending the new WheelEvent
type MouseWheelEventCompat = WheelEvent & {
  wheelDelta?: number;
  wheelDeltaY?: number;
};

export default function disableSmoothScroll(): void {
  const { isIE, isEdge } = getDeviceInfo();

  if (isIE || isEdge) {
    window.addEventListener("mousewheel", (event: MouseWheelEventCompat) => {
      const { wheelDelta, wheelDeltaY } = event as any;

      event.preventDefault();
      linearSmoothScrollBy(1 - (wheelDeltaY || wheelDelta));
    });

    document.body.addEventListener("keydown", (event: KeyboardEvent) => {
      switch (event.key) {
        case "PageUp":
          event.preventDefault();
          linearSmoothScrollBy(window.innerHeight * -0.8);
          break;

        case "PageDown":
          event.preventDefault();
          linearSmoothScrollBy(window.innerHeight * 0.8);
          break;

        case "ArrowUp":
          event.preventDefault();
          linearSmoothScrollBy(-120);
          break;

        case "ArrowDown":
          event.preventDefault();
          linearSmoothScrollBy(120);
          break;

        default:
          return;
      }
    });
  }
}

/**
 * Scroll by an amount, linearly, in a fixed time (using only integer increments)
 * @param y The position to scroll by
 */
function linearSmoothScrollBy(y: number, milliseconds: number = 100): void {
  let steps = 10;
  const stepY = y / steps;

  const interval = setInterval(() => {
    window.scrollTo(0, Math.round(window.pageYOffset + stepY));
    if (steps > 0) {
      steps--;
    } else {
      clearInterval(interval);
    }
  }, milliseconds / steps);
}
