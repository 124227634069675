import cx from "classnames/dedupe";

import "../core/globals";
import once from "../utils/dom-event-once";
import { requestAnimationFrame } from "../utils/request-animation-frame";
import "./instant-image.scss";

export default function initInstantImages() {
  window.Shorthand.initInstantImage = initInstantImage;
  // For images that were set before Shorthand II was ready
  if (window.SHPreloadInstantImages) {
    window.SHPreloadInstantImages.forEach(el => {
      initInstantImage(el);
    });
    delete window.SHPreloadInstantImages;
  }
}

export function hideInstantImage(container: Element) {
  const classNames = {
    "InstantImage--isLoading": false,
    "InstantImage--isLoaded": true,
  };

  container.className = cx(container.className, classNames);
}

export function initInstantImage(container: Element) {
  const realImg = container.querySelector("[data-instant-image-real-img]") as HTMLImageElement;
  const isRealImgLoaded = realImg.complete;

  const onLoad = () => {
    requestAnimationFrame(() => hideInstantImage(container));
  };

  if (isRealImgLoaded) {
    onLoad();
  } else {
    once(realImg, "load", onLoad);
  }
}
