import pageVisibility from "../dom-helpers/page-visibility";
import { requestAnimationFrame } from "../utils/request-animation-frame";

type Loopable = (time?: number) => void;
const loopables: Loopable[] = [];
let isLoopRunning: boolean = false;

function execute(time: number) {
  // console.time('loop');
  loopables.forEach(loopable => loopable(time));
  // console.timeEnd('loop');
}

function loop(time: number) {
  execute(time);

  if (isLoopRunning) {
    requestAnimationFrame(loop);
  }
}

let listening = false;
export function initLoop() {
  if (isLoopRunning) {
    // don't start loop if it's already looping!
    return;
  }

  if (!listening) {
    pageVisibility.addVisibilityChangeListener(() => {
      if (pageVisibility.isHidden()) {
        stopLoop();
      } else {
        initLoop();
      }
    });
    listening = true;
  }

  if (pageVisibility.isHidden()) {
    // don't start loop if the page ain't visible
    return;
  }

  isLoopRunning = true;
  requestAnimationFrame(loop);
}

export function stopLoop() {
  isLoopRunning = false;
}

export function addToLoop(fn: Loopable) {
  if (loopables.indexOf(fn) === -1) {
    loopables.push(fn);
  }
  initLoop();
}

export function unLoop(fn: Loopable) {
  const index = loopables.indexOf(fn);
  if (index !== -1) {
    loopables.splice(index, 1);
  }
}
