import fastdom from "fastdom";

export function toggleClassName(el: HTMLElement, className: string, force?: boolean): boolean | void {
  if (force !== undefined) {
    return ensureClassName(el, className, force);
  }
  return el.classList.toggle(className);
}

export function updateDisplay(el: HTMLElement | HTMLElement[], display: string): void {
  const elements = Array.isArray(el) ? el : [el];
  elements.forEach(element => {
    fastdom.mutate(() => {
      element.setAttribute("style", "display: " + display);
    });
  });
}

export function removeElement(el: HTMLElement | HTMLElement[]): void {
  const elements = Array.isArray(el) ? el : [el];
  elements.forEach(element => {
    fastdom.mutate(() => {
      element.parentNode.removeChild(element);
    });
  });
}

export function ensureClassName(el: HTMLElement, className: string, toggle: boolean): void {
  const existingClasses = el.className.split(/\s+/);
  const isExists = existingClasses.includes(className);
  const classes =
    toggle && !isExists
      ? [...existingClasses, className]
      : !toggle && isExists
        ? existingClasses.filter(cx => cx !== className)
        : existingClasses;

  el.className = classes.join(" ");
}
