type TNoneInOut = "none" | "in" | "out" | "both";

export type TConfig = {
  textPerLine?: boolean;
  textFade?: TNoneInOut;
  textZoom?: TNoneInOut;
  textBlur?: TNoneInOut;
  backgroundFade?: TNoneInOut;
  backgroundZoom?: TNoneInOut;
  backgroundDollyMagnitude?: number;
  backgroundBlur?: TNoneInOut;
  backgroundGrayscale?: TNoneInOut;
  needsBackgroundClone?: boolean;
  hasEffects: boolean;
};

export const STATIC_DEFAULTS: TConfig = {
  textPerLine: false,
  textFade: "none",
  textZoom: "none",
  textBlur: "none",
  backgroundFade: "none",
  backgroundZoom: "none",
  backgroundBlur: "none",
  backgroundGrayscale: "none",

  hasEffects: false,
};

export const ANIMATED_DEFAULTS: TConfig = {
  textPerLine: false,
  textFade: "none",
  textZoom: "none",
  textBlur: "none",
  backgroundFade: "none",
  backgroundDollyMagnitude: -1,
  backgroundBlur: "none",
  backgroundGrayscale: "none",
  hasEffects: true,
};

/**
 * Decode the config for a section
 * @param element the parent element
 * @returns the effects config
 */
export function getConfig(element?: Element | null, defaults: TConfig = STATIC_DEFAULTS): TConfig {
  try {
    return {
      ...defaults,
      ...JSON.parse(window.atob(element.getAttribute("data-effects"))),
    };
  } catch (err) {
    return { ...defaults };
  }
}
