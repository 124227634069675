import { ScrolledElement } from "../components/scrolled-element";
import "./background-transition.scss";
import { getVisibleCaptions } from "./captions";
import initTextItemFades from "./fade-text-items";
import { ILayoutAttributes, ITransitionRule, calculateTransitions, layoutScene, parseTransitionRules } from "./layout";
import "./media-renderer.scss";

const SHADOW_HTML = `
<style type="text/css">
.PositionSticky {
  position: -webkit-sticky; /* Safari 12 and earlier */
  top: 0px;
  position: sticky;
}
</style>
<div class="PositionSticky" style="width: 100%; height: 100vh; overflow: hidden">
 <div style="width: 100%; height: 100vh; overflow: hidden; top: 0px; isolation: isolate">
 <slot></slot>
 </div>
 <slot name="caption"></slot>
</div>
<slot name="foreground"></slot>`;

/**
 * Web component that transitions through different background slides while scrolling through the
 * foreground, as currently used by Reveal sections. Additionally if the slides contain caption
 * elements (child elements with a "Theme-OverlayedCaption" class), they will be rendered as appropriate
 * in the top-level caption container.
 *
 * Example usage:
 *
 * <sh-background-transition data-transitions="1 fade; 1 up">
 *  <div><img class="FullSize" src="slide1.jpg"></div>
 *  <div><img class="FullSize" src="slide2.jpg"></div>
 *  <div><img class="FullSize" src="slide3.jpg"></div>
 *  <div slot="caption" data-mediarenderer-caption-display className="Theme-OverlayedCaption MediaRenderer__fixedCaption MediaRenderer__captionDisplay">
 *   <div data-mediarenderer-caption-container className="Theme-Caption Layout" />
 *  </div>
 *  <div slot="foreground">
 *   <div class="FullSize">Slide 1 text</div>
 *   <div class="FullSize">Slide 2 text</div>
 *   <div class="FullSize">Slide 3 text</div>
 *  </div>
 * </sh-background-transition>
 *
 * Each direct child of the sh-background-transition element that does not have an explicit slot is treated
 * as an independent slide to be managed.
 *
 * Note: the element in the foreground slot must have the same number of children as there are background slides.
 *
 * The data-transitions attribute defines the transition 'rules' between each slide - it contains pairs
 * of speed and type, where speed is a number that adjusts the transition duration (as a fraction of the viewport size)
 * and type is one of "none", "fade", "up", "down", "left" or "right" (or a comma-separated list of
 * those types for combined transitions). There should be 1 less transition than the number of slides
 * (the initial slide does not transition in, nor does the final slide transition out).
 *
 * The actual transition points are determined by the height of each foreground child element.
 *
 * Note 2: This element is currently explicitly built for reveal sections, but it should be able to be extended
 * to also cover BGS and other cases (just needs different ways of managing the transition points)
 */
export class BackgroundTransitionElement extends ScrolledElement {
  private transitions: ITransitionRule[];
  private slotElements: HTMLSlotElement[];
  private textHeights: number[] = [];
  private textObserver?: ResizeObserver;
  private captionElement: HTMLElement | undefined;
  private textBlockElement: HTMLElement;
  private isTextBlockConnected = false;

  public static observedAttributes = ["data-transitions"];

  constructor() {
    super();
    const shadow = this.attachShadow({ mode: "open" });
    shadow.innerHTML = SHADOW_HTML;
    this.slotElements = Array.from(shadow.querySelectorAll("slot"));

    if (window.ResizeObserver) {
      this.textObserver = new ResizeObserver(() => {
        if (this.textBlockElement) {
          this.textHeights = getTextHeights(this.textBlockElement);
        }
      });
    }

    this.slotElements[0].addEventListener("slotchange", () => {
      this.updateScene(this.getScrollPosition());
    });
    this.slotElements[1].addEventListener("slotchange", () => {
      this.captionElement = this.slotElements[1].assignedElements()[0] as HTMLElement;
      const position = this.getScrollPosition();
      if (this.captionElement && position.isVisible) {
        this.captionElement.style.display = "block";
        this.updateScene(position);
      }
    });
    this.slotElements[2].addEventListener("slotchange", () => {
      this.disconnectTextBlockElement();
      this.textBlockElement = this.slotElements[2].assignedElements()[0] as HTMLElement;
      this.connectTextBlockElement();
      this.updateScene(this.getScrollPosition());
    });

    this.on("connected", () => {
      // FIXME: this should be part of the foreground content rather than being managed here,
      // but is currently grandfathered from the previous reveal code.
      initTextItemFades(this);

      this.readTransitions();
      this.connectTextBlockElement();
    });

    this.on("disconnected", () => {
      this.disconnectTextBlockElement();
    });

    this.on("enter", () => {
      if (this.captionElement) {
        this.captionElement.style.display = "block";
      }
    });

    this.on("scroll", event => {
      this.updateScene(event);
    });

    this.on("leave", () => {
      if (this.captionElement) {
        this.captionElement.style.display = "none";
      }
    });
  }

  private connectTextBlockElement(): void {
    if (this.isConnected && this.textBlockElement && !this.isTextBlockConnected) {
      this.textHeights = getTextHeights(this.textBlockElement);
      this.textObserver?.observe(this.textBlockElement);
      this.isTextBlockConnected = true;
    }
  }

  private disconnectTextBlockElement(): void {
    if (this.textBlockElement && this.isTextBlockConnected) {
      this.textHeights = [];
      this.textObserver?.observe(this.textBlockElement);
      this.isTextBlockConnected = false;
    }
  }

  private readTransitions(): void {
    this.transitions = parseTransitionRules(this.getAttribute("data-transitions"));
  }

  // scrollPercentage is the height we've scrolled through the whole thing
  // heightPercentage is the height of a single screen as a percentage of the scrollable element's height
  // scrollHeight is the actual height of the scrollable element in pixels
  private updateScene({
    scrollPercent,
    viewportHeight,
    elementHeight,
  }: {
    scrollPercent: number;
    viewportHeight: number;
    elementHeight: number;
  }): void {
    if (this.textHeights.length === 0) {
      /* Wait for the text frames to arrive */
      return;
    }

    const transitions = calculateTransitions(this.transitions, this.textHeights, viewportHeight, elementHeight);
    const layout = layoutScene(transitions, scrollPercent);
    const captions = getVisibleCaptions(layout, scrollPercent, viewportHeight, elementHeight);
    const elements = this.slotElements[0].assignedElements() as HTMLElement[];
    applyCaptions(this.captionElement, elements, captions);
    layout.forEach((attr, index) => applyElementLayout(elements[index], attr));
  }

  attributeChangedCallback(): void {
    if (this.isConnected) {
      this.readTransitions();
      this.updateScene(this.getScrollPosition());
    }
  }
}

function getTextHeights(element: HTMLElement): number[] {
  return Array.from(element.children).map(text => text.getBoundingClientRect().height);
}

function applyElementLayout(element: Element | undefined, attrs: ILayoutAttributes): void {
  if (element instanceof HTMLElement) {
    if (element.style.display !== attrs.display) {
      if (attrs.display === "none") {
        elementHidden(element);
      } else {
        elementVisible(element);
      }
      element.style.display = attrs.display;
    }
    element.style.opacity = attrs.opacity?.toString();
    element.style.clipPath = attrs.clipPath;
    element.style.zIndex = attrs.index.toString();
  }
}

function elementHidden(element: HTMLElement): void {
  /* Note: this is a bit of a temporary hack; ideally the video would manage its own lifecycle, but currently we
   * want to limit the video playback to strictly when it's actually on screen (unlike current background-video).
   * When STO-4555 is done, we can get rid of this and leave the videos on normal autoplay.
   */
  element.querySelectorAll("video").forEach(video => {
    if (video.autoplay) {
      video.autoplay = false;
    }
    try {
      video.pause();
    } catch (err) {
      /* Ignore: this can be triggered by trying to pause something that isn't ready */
    }
  });
}

function elementVisible(element: HTMLElement): void {
  element.querySelectorAll("video").forEach(video => {
    if (video.src) {
      try {
        video.play().catch(() => {
          /* Ignore. Note this is _not_ redundant, despite why Sonar thinks. Depending on the browser,
           * video.play() may immediately throw an exception in some cases, and reject the promise
           * in others. YARLY
           */
        });
      } catch (err) {
        /* Also ignore errors here: this can be triggered spuriously under certain timing conditions
         * and there isn't really anything we can do about it.
         */
      }
    } else {
      video.autoplay = true;
    }
  });
}

function applyCaptions(captionElement: HTMLElement | undefined, slideElements: HTMLElement[], captions: ILayoutAttributes[]): void {
  /* Note: this currently copies the caption from the slide background onto the top-level caption element, which
   * is not necessarily efficient and potentially confusing CSS-wise; it does however have the advantage that the
   * slide backgrounds can maintain their own captions and we copy it out for rendering purposes.
   */
  if (captionElement) {
    const container = captionElement.querySelector<HTMLElement>("[data-mediarenderer-caption-container]") || captionElement;
    const slideCaptions = captions.map(frame => slideElements[frame.index].querySelector(".Theme-OverlayedCaption"));
    if (captions.length === 0 || slideCaptions.some(elem => !elem)) {
      captionElement.style.opacity = "0";
    } else {
      captionElement.style.opacity = "1";
      if (captions.length === 2) {
        container.style.opacity = "0";
      } else {
        container.style.opacity = "1";
        container.innerHTML = slideCaptions[0].innerHTML;
      }
    }
  }
}

function registerBackgroundTransition(name: string = "sh-background-transition"): void {
  /* Note: if we're embedded, the element might already have been defined. It may not be the exact same version,
   * but we're limited in what we can do here (unless we implement our own proxying etc)
   * Scoped registries would also make this problem go away (which seems to be actively progressing, at least)
   */
  if (!customElements.get(name)) {
    customElements.define(name, BackgroundTransitionElement);
  }
}

registerBackgroundTransition();
