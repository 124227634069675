import fastdom from "fastdom";

import DisplayContainer from "../display-container";
import query from "../dom-helpers/query";
import { addToLoop } from "../loop";

let running = false;
export default function initScrollymoly(getTopOffset?: (el: HTMLElement) => number) {
  if (getTopOffset) scrollymoly.getTopOffset = getTopOffset;

  const moles = query("[data-scrollymoly]");
  moles.forEach(initScrollymole);

  window.addEventListener("orientationchange", () => render());

  if (!running) {
    running = true;
    window.addEventListener("resize", updateTopOffsets);
    addToLoop(render);
  }
}

export enum EffectKinds {
  TranslateDown = "translateDown",
  FadeIn = "fadeIn",
  FadeOut = "fadeOut",
}

export interface Effect {
  kind: EffectKinds;
  offset: number;
  start: number;
  end: number;
}

interface State {
  lastOpacity?: number;
}

export interface Mole {
  element: HTMLElement;
  basisEl: HTMLElement;
  effects: Effect[];
  state: State;
  topOffset: number;
}

interface Scrollymoly {
  moles: Mole[];
  getTopOffset(el: HTMLElement): number;
}
const scrollymoly: Scrollymoly = {
  moles: [],
  getTopOffset(el: HTMLElement) {
    // Guard clause to ensure documentElement is not null
    if (!document.documentElement) {
      console.error("Document element is not available.");
      return 0; // Return a default offset
    }
    return el.getBoundingClientRect().top + document.documentElement.scrollTop;
  },
};

export function initScrollymole(moleEl: HTMLElement) {
  const effectsJson = moleEl.getAttribute("data-scrollymoly");

  try {
    const effects: Effect[] = JSON.parse(effectsJson).map((effect: Effect) => ({
      ...effect,
      offset: Number.isFinite(effect.offset) ? effect.offset : 0,
    }));
    const basisEl = moleEl.closest("[data-scrollymoly-basis]") as HTMLElement;
    const topOffset = scrollymoly.getTopOffset(basisEl);
    pushMole({ element: moleEl, basisEl, effects, topOffset, state: {} });
  } catch (e) {
    console.error(e);
  }
}

export function pushMole(mole: Mole) {
  scrollymoly.moles.push(mole);
  render();
}

export function removeMole(mole: Mole) {
  const index = scrollymoly.moles.findIndex(m => m.element === mole.element);
  if (index !== -1) {
    resetMole(scrollymoly.moles[index]);
    scrollymoly.moles.splice(index, 1);
  }
  render();
}

export function setMole(mole: Mole) {
  const existingMole = scrollymoly.moles.find(m => m.element === mole.element);
  if (!existingMole) {
    return pushMole(mole);
  } else if (!mole.effects.length) {
    return removeMole(mole);
  }

  Object.assign(existingMole, mole);
  resetMole(existingMole);
  render();
}

export function render() {
  scrollymoly.moles.map(renderMole);
}

function updateTopOffsets() {
  scrollymoly.moles.forEach((mole: Mole) => {
    mole.topOffset = scrollymoly.getTopOffset(mole.basisEl);
  });
}

function renderMole(mole: Mole) {
  return new Promise<void>((resolve, reject) => {
    fastdom.measure(() => {
      mole.effects.forEach(effect => {
        if (effect.kind in effects) {
          effects[effect.kind](mole, effect, resolve);
        } else {
          console.error(`Unknown effect type '${effect.kind}'`);
          resolve();
        }
      });
    });
  });
}

export function resetMole(mole: Mole) {
  fastdom.mutate(() => {
    Object.assign(mole.element.style, { opacity: null, transform: null });
  });
}

function calculatePercentage(effect: Effect, basisEl: HTMLElement, topOffset: number): number {
  // We want the height of the text block (firstElementChild) not the section itself,
  // so that they fadeout nicely.
  const boundingRect = basisEl.firstElementChild.getBoundingClientRect();
  const threshold = effect.kind === EffectKinds.FadeIn ? boundingRect.top : boundingRect.bottom;

  const minimumOpacityPosition = DisplayContainer.getHeight() * effect.end;
  const maximumOpacityPosition = minimumOpacityPosition + Math.min(boundingRect.height, DisplayContainer.getHeight() * effect.start);
  const percentage =
    threshold > maximumOpacityPosition ? 1 : (threshold - minimumOpacityPosition) / (maximumOpacityPosition - minimumOpacityPosition);

  return Math.round(percentage * 100) / 100;
}

interface EffectFunctions {
  [key: string]: (mole: Mole, effect: Effect, resolve: () => void) => void;
}
const effects: EffectFunctions = {
  fadeOut(mole: Mole, effect: Effect, resolve: () => void) {
    const percentage = calculatePercentage(effect, mole.basisEl, mole.topOffset);
    const opacity = Math.max(0, Math.min(1, percentage));

    if (mole.state.lastOpacity === opacity) return resolve();

    fastdom.mutate(() => {
      mole.state.lastOpacity = opacity;
      mole.element.style.opacity = opacity.toString();
      resolve();
    });
  },

  fadeIn(mole: Mole, effect: Effect, resolve: () => void) {
    const percentage = calculatePercentage(effect, mole.basisEl, mole.topOffset);
    const opacity = Math.max(0, Math.min(1, 1 - percentage));

    if (mole.state.lastOpacity === opacity) return resolve();

    fastdom.mutate(() => {
      mole.state.lastOpacity = opacity;
      mole.element.style.opacity = opacity.toString();
      resolve();
    });
  },
};
