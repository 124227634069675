/*
 * Note: In theory we should be able to use the ScreenOrientation API, but unfortunately
 * Safari only added support in 16.4. ie this is effectively a polyfill.
 */
import DisplayContainer from "../display-container";

export type Orientation = "landscape" | "portrait";

function determineOrientation(): Orientation {
  return DisplayContainer.getWidth() < 900 ? "portrait" : "landscape";
}

export type OrientationListener = (orientation: Orientation) => void;
let orientationListeners: OrientationListener[] = [];
let currentOrientation: Orientation | undefined;

export function getCurrentOrientation(): Orientation {
  if (!currentOrientation) {
    currentOrientation = determineOrientation();
  }
  return currentOrientation;
}

export function addOrientationListener(listener: OrientationListener): void {
  if (orientationListeners.length === 0) {
    initOrientation();
  }
  orientationListeners.push(listener);
}
export function removeOrientationListener(listener: OrientationListener): void {
  orientationListeners = orientationListeners.filter(fn => fn !== listener);
  if (orientationListeners.length === 0) {
    shutdownOrientation();
  }
}

function onOrientationChange(): void {
  const orientation = determineOrientation();
  if (orientation !== currentOrientation) {
    currentOrientation = orientation;
    orientationListeners.forEach(fn => fn(orientation));
  }
}

function initOrientation(): void {
  window.addEventListener("resize", onOrientationChange, { passive: true });
  window.addEventListener("orientationchange", onOrientationChange, { passive: true });
}

function shutdownOrientation(): void {
  window.removeEventListener("resize", onOrientationChange);
  window.removeEventListener("orientationchange", onOrientationChange);
}
