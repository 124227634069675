interface Mode {
  getCache(): {
    width: number;
    height: number;
    top: number;
    bottom: number;
  };
  measuringElement?: HTMLElement;
}

interface ShorthandGlobal {
  initInstantImage?: (container: Element) => void;
  initFocalPointPictures?: (picture: HTMLElement) => void;
  lazyloadTriggerMargin?: string;

  navigateInProject?: (path: string, historyURL: string) => void;
  displayContainer?: Mode;
}
interface Window {
  Shorthand: ShorthandGlobal;
  SHPreloadInstantImages: HTMLElement[];
  $shproject?: any;
  $shproject_ext_search?: any;
}

if (!window.hasOwnProperty("Shorthand")) {
  window.Shorthand = {};
}
