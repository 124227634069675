import queryAll from "../dom-helpers/query";
import { doYouEvenFitObjects } from "../object-fit/index";
import { addParentWidthElement } from "../parent-width/index";
import "./index.scss";

export default function initSplitLayouts() {
  queryAll(".SplitLayout").forEach(initSplitLayout);
}

export function initSplitLayout(splitLayout: HTMLElement) {
  // hack to conditionally apply ParentWidth to fixed background for non-IE
  const fixedCoverBackgrounds = queryAll(".FullSize__fixedChild.ObjectFit--cover", splitLayout);
  if (doYouEvenFitObjects()) {
    fixedCoverBackgrounds.forEach(addParentWidthElement);
  }
}
