import pageVisibilityInfo from "page-visibility-shim";

const pageVisibility = {
  /**
   * @return boolean
   */
  isHidden() {
    return document[pageVisibilityInfo?.hidden];
  },

  /**
   * @param cb Function
   * @return void
   */
  addVisibilityChangeListener(cb) {
    return document.addEventListener(pageVisibilityInfo.visibilityChange, cb);
  },
};

export default pageVisibility;
