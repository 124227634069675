import scrollwatch, { TriggerPoints, isVisible, unobserve } from "../scrollwatch";
import pageVisibility from "./page-visibility";

/**
 * onVisibilityChange
 * Fires callbacks when the given elements visibility changes.
 *
 * It uses scrollwatch and the Page Visibility API to determine an elements visibility.
 * This is a great way to start and stop rAF loops that are isolated to a
 * particular element, so that loop code only runs while the element is actually visible on the page.
 */

export const HIDDEN = Symbol("hidden");
export type HIDDEN = typeof HIDDEN;
export const VISIBLE = Symbol("visible");
export type VISIBLE = typeof VISIBLE;

type Visibility = HIDDEN | VISIBLE;
type Callback = (visibility: Visibility) => void;
type LastCall = [Callback, Visibility];

/**
 * A helper to prevent executing callbacks with the same visibility
 * @param Function cb
 * @param VISIBLE|HIDDEN visibility
 */
export function triggerCallback(cb: Callback, visibility: Visibility, lastCalls: LastCall[]) {
  // util to find the provided cb in the lastCalls array
  // find the lastCall state
  const lastCall = lastCalls.find(([f]) => f === cb);
  const lastVisibility = lastCall ? lastCall[1] : false;

  if (!lastVisibility || lastVisibility !== visibility) {
    // execute cb if visibility has changed (or no last visibility)
    cb(visibility);
  }

  // update last call in state array with visibility
  return lastCalls.filter(([f]) => f !== cb).concat([[cb, visibility]]);
}

export function render(state: State) {
  let { lastCalls } = state;
  observables.forEach(obs => {
    const isHidden = state.isPageHidden || !state.visibleElements.has(obs.element);
    const observableVisibility = isHidden ? HIDDEN : VISIBLE;
    lastCalls = triggerCallback(obs.cb, observableVisibility, lastCalls);
  });
  state.lastCalls = lastCalls;
}

export function addVisibleElement(visibleElements: Set<Element>, element: Element) {
  return visibleElements.add(element);
}

export function removeVisibleElement(visibleElements: Set<Element>, element: Element) {
  visibleElements.delete(element);
  return visibleElements;
}

/**
 * State and stateful functions
 */

export function initPageVisibilityChangeListener() {
  pageVisibility.addVisibilityChangeListener(() => {
    const newState: NewState = { isPageHidden: isPageHidden() };
    setState(newState);
  });
}

export function isPageHidden() {
  return pageVisibility.isHidden();
}

interface Observable {
  cb: Callback;
  element: Element;
}
const observables: Observable[] = [];
interface NewState {
  visibleElements?: Set<Element>;
  isPageHidden?: boolean;
}

interface State extends NewState {
  isPageHidden: boolean;
  visibleElements: Set<Element>;
  lastCalls: LastCall[];
}

let state: State = {
  isPageHidden: isPageHidden(),
  visibleElements: new Set(),
  lastCalls: [],
};

export function onVisibilityChange(element: Element, cb: Callback, triggerPoint = TriggerPoints.Near): () => void {
  observables.push({ cb, element });
  const watchOptions = { triggerPoint };
  const elementIsInView = (entry: IntersectionObserverEntry) => {
    const { visibleElements } = state;
    const newVisibleElements = isVisible(entry)
      ? addVisibleElement(visibleElements, element)
      : removeVisibleElement(visibleElements, element);

    setState({ visibleElements: newVisibleElements });
  };

  scrollwatch(element, elementIsInView, watchOptions);

  // return unlisten function
  return () => {
    unobserve(element, watchOptions);
    const index = observables.findIndex(ob => ob.element === element && ob.cb === cb);
    observables.splice(index, 1);
  };
}

export function setState(newState: NewState) {
  state = { ...state, ...newState };
  render(state);
}

export default function initOnVisible() {
  initPageVisibilityChangeListener();
}
