interface IEventEmitter<T extends Record<string, any[]>> {
  on<K extends keyof T>(event: K, listener: (...event: T[K]) => void): boolean;
  off<K extends keyof T>(event: K, listener: (...event: T[K]) => void): boolean;
  emit<K extends keyof T>(event: K, ...body: T[K]): void;
  hasListener<K extends keyof T>(event: K): boolean;
}

type IEventListeners<T extends Record<string, any[]>> = { [K in keyof T]?: Array<(...event: T[K]) => void> };

export function eventEmitter<T extends Record<string, any>>(): IEventEmitter<T> {
  const listeners: IEventListeners<T> = {};
  return {
    /**
     * Add a listener on the given event.
     * @param event
     * @param listener
     * @returns true if this is the first listener added on this event type.
     */
    on<K extends keyof T>(event: K, listener: (...event: T[K]) => void): boolean {
      if (listeners[event]) {
        listeners[event].push(listener);
      } else {
        listeners[event] = [listener];
        return true;
      }
    },

    /**
     * Remove a listener for the given event.
     * @param event
     * @param listener
     * @returns true if we just removed the last listener on this event type.
     */
    off<K extends keyof T>(event: K, listener: (...event: T[K]) => void) {
      if (listeners[event]) {
        const idx = listeners[event].indexOf(listener);
        if (idx !== -1) {
          if (listeners[event].length === 1) {
            delete listeners[event];
            return true;
          } else {
            listeners[event].splice(idx, 1);
          }
        }
      }
      return false;
    },

    /**
     * Notify all listeners (if any) of the given event.
     * @param event
     * @param body
     */
    emit<K extends keyof T>(event: K, ...body: T[K]): void {
      listeners[event]?.forEach(listener => listener(...body));
    },

    hasListener<K extends keyof T>(event: K) {
      return event in listeners;
    },
  };
}

interface IComposableElementEvents {
  connected: [];
  disconnected: [];
}
