import arrayFrom from "array-from/polyfill";

import DisplayContainer from "../display-container";
import query from "../dom-helpers/query";
import { Lazyloadable, initLazyloadable } from "../lazyload/index";
import { addToLoop, unLoop } from "../loop/index";
import { Item, ItemMachine, ScrollmationInstance, ScrollmationState, renderScrollmation } from "../scrollmation/index";
import "./background-scrollmation.scss";

const state: ScrollmationState = { instances: new Map(), isRunning: false };

type ItemElement = HTMLElement;
const triggerElements: WeakMap<ItemElement, HTMLElement> = new WeakMap();

function getStateByDOMElement(el: HTMLElement): ScrollmationInstance {
  const instances = state.instances;
  if (instances.has(el)) {
    return instances.get(el);
  }

  instances.set(el, {
    scrollContainer: el.parentElement,
    items: [],
    isRendering: false,
    machines: new WeakMap(),
  });
  return instances.get(el);
}

function createItemFromEl(itemEl: HTMLElement, index: number, itemEls: HTMLElement[]): Item {
  const itemLength = 1 / itemEls.length;
  const showItemAt = index * itemLength;
  const showUntil = showItemAt + itemLength;
  const showDuringScrollIn = index === 0;
  const showDuringScrollOut = index === itemEls.length - 1;
  const data = {
    // values too low can cause CSS transitions to fail
    start: showItemAt,
    end: showUntil,
    showDuringScrollIn,
    showDuringScrollOut,
  };

  return {
    itemEl,
    index,
    data,
  };
}

function captionDisplay(): void {
  const backgroundScrollmations = document.querySelectorAll(".Theme-BackgroundScrollmationSection");

  backgroundScrollmations.forEach(item => {
    const infoButtons = item.querySelectorAll(".MediaRenderer__fixedCaption--icon");
    const captionModal = item.querySelector(".MediaRenderer__Scrollmation-portrait--Caption") as HTMLElement;
    const closeButtons = item.querySelectorAll(".btn-close-caption-scrollmation");

    function openCaptionModal(): void {
      captionModal.removeAttribute("style");
    }

    function close(): void {
      captionModal.style.display = "none";
    }

    infoButtons.forEach(btn => btn.addEventListener("click", openCaptionModal));
    closeButtons.forEach(btn => btn.addEventListener("click", close));
  });
}
export default function initBackgroundScrollmations(): void {
  const backgroundScrollmations = query("[data-background-scrollmation]");
  captionDisplay();

  backgroundScrollmations.forEach(el => initBackgroundScrollmation(el, { doLazyload: true }));

  ["resize", "orientationchange"].forEach(eventName =>
    window.addEventListener(eventName, updateLazyloadTriggerPositions, {
      passive: true,
    })
  );
}

interface InitOptions {
  doLazyload: boolean;
}
export function initBackgroundScrollmation(
  el: HTMLElement,
  options: InitOptions,
  onItemShown?: (item: any, itemIndex: number) => void
) {
  const itemsEls = query("[data-background-scrollmation-item]", el);

  if (itemsEls.length === 0) {
    return () => {};
  }
  const newItems = itemsEls.map(createItemFromEl);
  const state = getStateByDOMElement(el);

  // prevent overwriting items with new ones
  // (the object is used as a reference elsewhere, resulting in duplicate State Machines).
  state.items = newItems.reduce((finalItems, currentItem) => {
    const existingItem = state.items.find(i => i.itemEl === currentItem.itemEl);

    if (existingItem) {
      existingItem.data = currentItem.data;
    }
    return [...finalItems, existingItem || currentItem];
  }, []);

  state.items
    .map(item => (options.doLazyload ? ensureItemIsLazyloadable(item, el.parentElement) : item))
    .filter(item => {
      // only create new state machines for items that don't have one
      const existingMachine = state.machines.get(item);
      return !existingMachine;
    })
    .forEach(item =>
      state.machines.set(
        item,
        new ItemMachine({
          item,
          classNames: {
            prime: "BackgroundScrollmationItem--isPrimed",
            active: "BackgroundScrollmationItem--isActive",
          },
          onItemShown(item: Item) {
            onItemShown && onItemShown(item, state.items.indexOf(item));
          },
          shouldLinger(item: Item) {
            const otherItems = state.items.filter(i => i !== item);
            const otherMachines = otherItems.map(i => state.machines.get(i));

            // are any other machines active?
            const hasActiveMachines = otherMachines.some(machine => machine.isActive());
            return !hasActiveMachines;
          },
        })
      )
    );

  function onScroll(): void {
    const bounds = el.parentElement.getBoundingClientRect();
    if (bounds.bottom < DisplayContainer.getTop() + DisplayContainer.getHeight()) {
      el.setAttribute("data-attach", "after");
    } else if (bounds.top < DisplayContainer.getTop()) {
      el.setAttribute("data-attach", "during");
    } else {
      el.setAttribute("data-attach", "before");
    }
  }

  /*
    /Workaround
  */

  if (!document.getElementById("editor-viewport")) {
    document.addEventListener("scroll", onScroll, { passive: true });
  }

  if (options.doLazyload) updateLazyloadTriggerPositions();
  addToLoop(onTick);
  return () => {
    unLoop(onTick);
    if (!document.getElementById("editor-viewport")) {
      document.removeEventListener("scroll", onScroll);
    }
  };
}

function ensureItemIsLazyloadable(item: Item, columnEl: HTMLElement) {
  const existingTriggerEl = triggerElements.get(item.itemEl);

  if (!existingTriggerEl) {
    const newTriggerEl = document.createElement("div");
    newTriggerEl.classList.add("BackgroundScrollmation__lazyloadTriggerElement");
    newTriggerEl.setAttribute("data-item-index", String(item.index));
    triggerElements.set(item.itemEl, newTriggerEl);
    columnEl.appendChild(newTriggerEl);

    const lazyloadable = {
      pictures: query("picture", item.itemEl) as HTMLPictureElement[],
      container: item.itemEl.querySelector("[data-lazyload-container]"),
      trigger: newTriggerEl,
    } as Lazyloadable;

    initLazyloadable(lazyloadable);
  }
  return item;
}

function updateLazyloadTriggerPositions() {
  arrayFrom(state.instances.values())
    .map(instance => instance.items)
    .map(items =>
      items.map(item => {
        const triggerElement = triggerElements.get(item.itemEl);
        Object.assign(triggerElement.style, {
          position: "absolute",
          top: `${item.data.start * 100}%`,
        });
      })
    );
}

function onTick() {
  state.instances.forEach(i => renderScrollmation(i));
}
