import clamp from "clamp";

import DisplayContainer from "../display-container";
import { query } from "../dom-helpers/query";

type TScrollInfo = { percent: number; threshold: number };

let banner: HTMLDivElement;

/**
 * Work out how far we have scrolled through a given element
 * @param element
 * @param minThreshold
 * @param maxThreshold
 * @returns The amount we have scrolled through an element
 */
export function getScrollProgress(element: HTMLElement, minThreshold: number = 0, maxThreshold: number = 1): TScrollInfo {
  const { top: elementTop, height: elementHeight } = element.getBoundingClientRect();

  if (banner === null) {
    banner = query(".Header[role=banner]") as HTMLDivElement;
  }
  const bannerHeight: number = banner?.offsetHeight ?? 0;

  // Account for zoomed elements affecting their own height readings.
  let scale: number = 1;
  const possibleScale = /scale\((.*)\)/.exec(element.style.getPropertyValue("transform") ?? "");
  if (possibleScale) {
    scale = parseFloat(possibleScale[1]);
  }

  const top: number = elementTop + (elementHeight - elementHeight / scale) / 2;
  const min: number = -elementHeight / scale + bannerHeight;
  const max: number = DisplayContainer.getHeight();
  const range: number = max - min;

  if (element.className.includes("Theme-BackgroundMedia") && elementHeight < DisplayContainer.getHeight() * 0.6) {
    maxThreshold *= 0.5;
  }

  return {
    threshold: clamp((DisplayContainer.getHeight() * 0.6) / range, minThreshold, maxThreshold),
    percent: clamp(1 - (top - min) / range, 0, 1),
  };
}

/**
 * Get an eased value from 0 - 1 when scrolling in and 1 - 0 when scrolling out.
 * @param percent The scroll progress through an element
 * @param threshold The top and bottom threshold relative to the whole scroll range
 * @param exponent [4] The ease expoenent
 * @returns An eased value that bounces between 0 -> 1 -> 0
 */
export function easeInOut(percent: number, threshold: number, exponent: number = 3): number {
  if (percent < threshold) return Math.pow(percent / threshold, exponent);

  if (percent > 1 - threshold) return Math.pow(1 - (percent - (1 - threshold)) / threshold, exponent);

  return 1;
}
