import "../core/globals";
import "./bot-hacks.scss";
import "./display-container.scss";
import initFullHeightFlex from "./full-height-flex";
import FullViewMode from "./full-view-mode";

const sizeCache = { width: 0, height: 0, top: 0, bottom: 0 };

interface DisplayContainer {
  getOrientation(): "landscape" | "portrait";
  getWidth(): number;
  getHeight(): number;
  getTop(): number;
  getBottom(): number;
  getVhUnitsInPixels(vhunits: number): number;
  getDisplayContainer(): Mode;
}

export function initDisplayContainer(mode: Mode = new FullViewMode()) {
  window.Shorthand.displayContainer = mode;

  initFullHeightFlex();
}

const facade: DisplayContainer = {
  getOrientation() {
    // Are we calling this before the display container has been initialized?
    if (!window.Shorthand.displayContainer) {
      return window.innerWidth > window.innerHeight ? "landscape" : "portrait";
    }
    return facade.getWidth() > facade.getHeight() ? "landscape" : "portrait";
  },
  getHeight() {
    return window.Shorthand.displayContainer.getCache().height;
  },
  getWidth() {
    return window.Shorthand.displayContainer.getCache().width;
  },
  getTop() {
    return window.Shorthand.displayContainer.getCache().top;
  },
  getBottom() {
    return window.Shorthand.displayContainer.getCache().bottom;
  },
  getVhUnitsInPixels(vhunits) {
    const oneHundredVhInPixels = facade.getHeight();
    return oneHundredVhInPixels * (vhunits / 100);
  },
  getDisplayContainer() {
    return window.Shorthand.displayContainer;
  },
};

export default facade;
