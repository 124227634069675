import queryAll, { query } from "../dom-helpers/query";

type OnCloseFunction = () => void;

export default class Lightbox {
  public static current: Lightbox;

  public element: HTMLElement;

  public media: Array<HTMLElement>;
  public currentMediaIndex: number;

  public counter: HTMLDivElement;

  public onClose: OnCloseFunction;

  constructor(element: HTMLElement) {
    this.element = element;
    this.counter = element.querySelector("[aria-live]");

    this.element.parentElement.removeChild(this.element);
    document.body.appendChild(this.element);

    this.currentMediaIndex = 0;
    this.media = queryAll("[data-media-gallery-index]", this.element);

    // Close the lightbox if the overlay is clicked
    this.element.addEventListener("click", () => {
      this.close();
    });
    // But don't close if the caption or media is clicked
    queryAll("img, .Videoplayer, .Theme-Caption", this.element).forEach(media => {
      media.addEventListener("click", e => e.stopPropagation());
    });

    const prevButton = query('[data-action="previous"]', this.element) as HTMLButtonElement;
    if (prevButton) {
      prevButton.addEventListener("click", event => {
        event.stopPropagation();
        this.prev();
      });
    }

    const nextButton = query('[data-action="next"]', this.element) as HTMLButtonElement;
    if (nextButton) {
      nextButton.addEventListener("click", event => {
        event.stopPropagation();
        this.next();
      });
    }

    const closeButton = query('[data-action="close"]', this.element) as HTMLButtonElement;
    closeButton.addEventListener("click", event => {
      event.stopPropagation();
      this.close();
    });

    // Stop the background scrolling behind the lightbox modal
    this.element.addEventListener("touchmove", event => event.preventDefault());

    // Set up swipe gestures
    let swipeStart = -1;
    queryAll("img, .Videoplayer", this.element).forEach(media => {
      media.addEventListener("touchstart", event => {
        swipeStart = event.touches[0].clientX;
      });
      media.addEventListener("touchend", event => {
        const swipeLength = event.changedTouches[0].clientX - swipeStart;
        const threshold = window.innerWidth * 0.1;

        if (swipeLength < -threshold) {
          this.next();
        } else if (swipeLength > threshold) {
          this.prev();
        }

        swipeStart = -1;
      });
    });
  }

  /**
   * Get any focasuable elements inside the lightbox
   */
  public getFocusable(): Array<HTMLElement> {
    const controls = queryAll("*[data-action]", this.element);
    const videoPlayer = query(`[data-media-gallery-index="${this.currentMediaIndex}"].active [tabindex]`, this.element) as HTMLElement;
    return controls.concat(videoPlayer).filter(f => f);
  }

  /**
   * Open the lightbox at a given index
   * @param index
   * @param onClose
   */
  public open(index: number, onClose?: OnCloseFunction): void {
    // Close the last lightbox if there was one
    Lightbox.current?.close(false);

    queryAll("video", this.element).forEach((video: HTMLVideoElement) => video.setAttribute("playsinline", "true"));

    // Hack to make sure the progress scrubber is the right height in IE and Edge
    /* istanbul ignore next */
    queryAll(
      [
        ".DeviceDetect--isEdge .MediaGallery--lightbox .plyr__progress--seek",
        ".DeviceDetect--isIE .MediaGallery--lightbox .plyr__progress--seek",
      ].join(",")
    ).forEach(seek => {
      seek.style.setProperty("width", "0");
      setTimeout(() => seek.style.removeProperty("width"), 100);
    });

    this.goToIndex(index);
    this.element.classList.add("MediaGallery--lightbox--open");
    Lightbox.current = this;

    document.body.classList.add("Body--hasLightbox");

    // Focus "Next"
    this.getFocusable()[1].focus();

    this.onClose = onClose;
  }

  /**
   * Close the lightbox
   * @param runOnClose
   */
  public close(runOnClose: boolean = true): void {
    this.element.classList.remove("MediaGallery--lightbox--open");
    document.body.classList.remove("Body--hasLightbox");
    queryAll("video", this.element).forEach((video: HTMLVideoElement) => video.pause());

    if (runOnClose && typeof this.onClose === "function") this.onClose();

    Lightbox.current = null;
  }

  /**
   * Go to the previous item
   */
  public prev(): void {
    // Loop back around if needed
    const prevIndex = this.currentMediaIndex === 0 ? this.media.length - 1 : this.currentMediaIndex - 1;
    this.goToIndex(prevIndex);
  }

  /**
   * Go to the next item
   */
  public next(): void {
    // Loop back around if needed
    const nextIndex = this.currentMediaIndex === this.media.length - 1 ? 0 : this.currentMediaIndex + 1;
    this.goToIndex(nextIndex);
  }

  /**
   * Go to a given indexed item
   * @param index
   */
  private goToIndex(index: number): void {
    this.media[this.currentMediaIndex].classList.remove("active");
    queryAll("video", this.element).forEach((video: HTMLVideoElement) => video.pause());

    this.currentMediaIndex = index;

    const media = query(`[data-media-gallery-index="${this.currentMediaIndex}"]`, this.element) as HTMLElement;
    media.classList.add("active");

    this.counter.innerText = `Item ${index + 1} of ${this.media.length}`;
  }
}
