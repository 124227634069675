/**
 * Register a one-time event listener. Something similar to jQuery’s `one`.
 *
 * NOTE! It doesn’t handle `target.removeEventListener(eventName, originalListener, useCapture)`.
 * Provides a `deregister` function instead.
 *
 * @param {EventTarget} target
 * @param {String} eventName
 * @param {Function} listener
 * @param {Boolean} [options]
 * @returns {Function} deregister
 */
export default function once(
  target: HTMLElement,
  eventName: string,
  listener: (event: Event) => void,
  options?: EventListenerOptions
): () => void {
  function deregister() {
    target.removeEventListener(eventName, handler, options);
  }

  function handler() {
    deregister();
    return listener.apply(this, arguments);
  }

  target.addEventListener(eventName, handler, options);

  return deregister;
}
