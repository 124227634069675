import { ILayoutAttributes } from "./layout";

export interface ICaptionsOptions {
  fadeInThreshold?: number;
  fadeOutThreshold?: number;
  hideWhenOutOfBounds?: boolean;
}

const DEFAULTS = {
  /**
   * How much of a reaval item is shown before it's caption fades in
   */
  fadeInThreshold: 0.7,
  /**
   * How much of the _next_ reveal item is shown to fade out the current caption
   */
  fadeOutThreshold: 0.15,
  /**
   * display: none the caption when the section isn't in view (ideal for Reveal-type sections)
   */
  hideWhenOutOfBounds: true,
};

/**
 * Return the list of frames whose captions should be displayed.
 *
 * @param frames The results of the main transition layout.
 * @param options Caption options.
 */
export function getVisibleCaptions(
  frames: ILayoutAttributes[],
  scrollPercentage: number,
  viewportHeight: number,
  elementHeight: number,
  options: ICaptionsOptions = DEFAULTS
): ILayoutAttributes[] {
  const heightPercentage = viewportHeight / (viewportHeight + elementHeight);
  const showFirstCaptionAt = heightPercentage * options.fadeInThreshold * 0.5 - heightPercentage;
  const showLastCaptionAt = 1 - heightPercentage;
  if (showFirstCaptionAt > scrollPercentage || scrollPercentage >= showLastCaptionAt) {
    return [];
  }

  const visibleFrames = frames
    .filter(frame => frame.transitionPercentage)
    .sort((a, b) => b.transitionPercentage - a.transitionPercentage);

  if (visibleFrames.length === 0) {
    return [];
  }

  const mostVisibleFrame = visibleFrames[0];
  const nextVisibleFrame = visibleFrames[1];

  if (mostVisibleFrame.transitionPercentage < options.fadeInThreshold) {
    // The most visible reveal item is barely visible, don't show caption display yet
    // (this is normal when the reveal section is initially scrolling into view)
    return [];
  }

  if (nextVisibleFrame) {
    const isPastInThreshold = nextVisibleFrame.transitionPercentage > options.fadeInThreshold;
    const isPastOutThreshold = nextVisibleFrame.transitionPercentage > options.fadeOutThreshold;

    if (isPastInThreshold) {
      return [nextVisibleFrame];
    } else if (isPastOutThreshold) {
      return [mostVisibleFrame, nextVisibleFrame];
    }
  }
  return [mostVisibleFrame];
}
