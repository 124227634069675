import { logEvent } from "../analytics";
import { makeAudioSourceListener } from "../audioboss";
import { getDeviceInfo } from "../device-detect";
import query from "../dom-helpers/query";

const DEFAULT_SELECTOR = "[data-videoplayer-youtube]";
const DEFAULT_SELECTOR_360 = '[data-projection="360"]';
const YOUTUBE_SCRIPT_ID = "__YOUTUBE_API_SCRIPT__";

declare namespace YT {
  interface YTOptions {
    videoId: string;
    playerVars: { rel: number };
    events: {
      onStateChange(event: Event): void;
    };
  }
  class Player {
    constructor(placeholder: HTMLElement, options: YTOptions);
    playVideo(): void;
    pauseVideo(): void;
    getIframe(): HTMLIFrameElement;
    getVideoData(): VideoData;
  }

  interface VideoData {
    title: string;
    video_id: string;
  }

  interface PlayerState {
    PLAYING: string;
    PAUSED: string;
    ENDED: string;
  }

  let PlayerState: PlayerState;

  interface Event {
    data: string;
    target: Player;
  }
}

/**
 * Ensure that the YouTube iFrame API script is loaded, without needless attempting
 * to load it multiple times via an ID check.
 * The callback will be executed by the 'onYouTubeIframeAPIReady' function.
 * https://developers.google.com/youtube/iframe_api_reference#Getting_Started
 */
interface State {
  whenApiReady: false | Promise<void>;
  players: YT.Player[];
}
const state: State = {
  whenApiReady: false,
  players: [],
};
export function ensureYoutubeScript(onYouTubeIframeAPIReady: () => void) {
  if (query('script[src="//www.youtube.com/player_api"]').length > 0) return;

  if (!state.whenApiReady) {
    state.whenApiReady = new Promise((resolve, reject) => {
      window.onYouTubeIframeAPIReady = () => resolve();

      const tag = document.createElement("script");
      tag.src = "//www.youtube.com/player_api";
      tag.id = YOUTUBE_SCRIPT_ID;
      tag.async = true;
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    });
  }

  state.whenApiReady.then(onYouTubeIframeAPIReady);
}

function getVideoEventName(event: YT.Event) {
  const EVENT_NAMES = {
    [YT.PlayerState.PLAYING]: "play",
    [YT.PlayerState.PAUSED]: "pause",
    [YT.PlayerState.ENDED]: "end",
  };

  return EVENT_NAMES[event.data];
}

/**
 * Initialize a specific YouTube placeholder
 */
export function initYoutubeVideo(placeholder: HTMLElement) {
  const videoId = placeholder.getAttribute("data-video-id");
  const player = new YT.Player(placeholder, {
    videoId,
    playerVars: { rel: 0 },
    events: {
      onStateChange(event) {
        if (event.data === YT.PlayerState.PLAYING) {
          const player = event.target;
          const doPause = player.pauseVideo.bind(player);
          const onPlay = makeAudioSourceListener(doPause, player.getIframe());

          onPlay();
        }

        const eventName = getVideoEventName(event);
        if (eventName) {
          logVideoEvent(eventName, event.target);
        }
      },
    },
  });
  return player;
}

export function logVideoEvent(eventName: string, player: YT.Player) {
  const data = player.getVideoData();
  const videoName = `${data.title} (${data.video_id})`;
  logEvent("Videos", eventName, videoName);
}

export function create360VideoLinks(videoPlaceholders360: HTMLElement[]) {
  videoPlaceholders360.forEach(placeholder => {
    const ytid = placeholder.getAttribute("data-video-id");
    const parent = placeholder.parentElement;
    const youtubeButton = document.createElement("div");
    const youtubeLink = document.createElement("a");

    placeholder.removeAttribute("data-videoplayer-youtube");
    parent.style.backgroundImage = "url('https://img.youtube.com/vi/" + ytid + "/maxresdefault.jpg')";
    parent.style.backgroundSize = "cover";
    youtubeLink.setAttribute("href", "http://www.youtube.com/watch?v=" + ytid);
    youtubeLink.setAttribute("target", "_blank");
    youtubeLink.className = "Theme-360Video-Link";
    parent.parentNode.insertBefore(youtubeLink, parent);
    youtubeLink.appendChild(parent);
    youtubeLink.appendChild(youtubeButton);
    youtubeButton.innerText = "Tap to view 360 video";
    youtubeButton.className = "Theme-360Video-Button";
  });
}

/**
 * Search for an YouTube placeholders in the DOM and init them all
 */
export default function initYoutubeVideos(selector = DEFAULT_SELECTOR, selector360 = DEFAULT_SELECTOR_360) {
  const videoPlaceholders360 = query(selector360) as HTMLElement[];
  if (getDeviceInfo().isiOS) {
    // This will swap out 360 videos for links
    create360VideoLinks(videoPlaceholders360);
  }

  const videoPlaceholders = query(selector);
  if (videoPlaceholders.length === 0) {
    return;
  }
  ensureYoutubeScript(() => {
    state.players = videoPlaceholders.map(initYoutubeVideo);
  });
}
