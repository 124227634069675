export function isFlexboxSupported() {
  const testEl = document.createElement("div");
  testEl.style.display = "flex";
  return testEl.style.display === "flex";
}

export default function initFlexboxUnsupported() {
  if (!isFlexboxSupported()) {
    document.documentElement.classList.add("Layout--flexboxUnsupported");
  }
}
