/**
 * Calculate inner height & width of DOM elements
 * (Basically width minus relevant padding)
 * Assumes box-sizing: border-box is in play.
 */

enum DimensionAxis {
  Width = "clientWidth",
  Height = "clientHeight",
}

const DIMENSION_STYLE_KEYS = {
  [DimensionAxis.Width]: ["padding-left", "padding-right", "border-left-width", "border-right-width"],
  [DimensionAxis.Height]: ["padding-top", "padding-bottom", "border-top-width", "border-bottom-width"],
};

export function getInnerWidth(element: Element) {
  return getInnerDimension(element, DimensionAxis.Width);
}

export function getInnerHeight(element: Element) {
  return getInnerDimension(element, DimensionAxis.Height);
}

function readDimension(element: Element, dimension: DimensionAxis) {
  switch (dimension) {
    case DimensionAxis.Width:
      return element.clientWidth;

    case DimensionAxis.Height:
      return element.clientHeight;
  }
}

function getInnerDimension(element: Element, dimension: DimensionAxis) {
  const styleKeys = DIMENSION_STYLE_KEYS[dimension];
  try {
    const computedStyles = window.getComputedStyle(element);
    const totalPadding = styleKeys.reduce((totalPadding, prop) => {
      const paddingValue = computedStyles.getPropertyValue(prop);
      return totalPadding + pxValueToNumber(paddingValue);
    }, 0);
    return readDimension(element, dimension) - totalPadding;
  } catch (e) {
    throw new Error(`Couldn't get styles for element ${element} in getInnerDimension`);
  }
}

/**
 * Convert pixel string into JavaScript Number
 */
function pxValueToNumber(px: string) {
  return parseInt(px, 10);
}
