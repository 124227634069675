import fastdom from "fastdom";

import query from "../dom-helpers/query";
import { addToLoop } from "../loop";

interface CardCanvasItemsState {
  items: Item[];
  lastSizes: WeakMap<Item, Size>;
}

interface Size {
  width: number;
  height: number;
  captionHeight: number;
}

interface Item {
  el: HTMLElement;
  img: HTMLImageElement[];
  caption: HTMLElement;
  heightReferenceEl: HTMLElement;
}

const state: CardCanvasItemsState = {
  items: [],
  lastSizes: new WeakMap(),
};

/**
 * Prevent captions from getting too narrow. Occurs on short viewports,
 * when the image gets downscaled significantly leaving little width for
 * the caption.
 */
const MIN_CAPTION_WIDTH = 250;

export function initCardCanvasItems() {
  const items = getItems(document.documentElement);
  if (items.length === 0) return;
  state.items = items;
  addToLoop(render);
}

export function addItemByEl(el: HTMLElement) {
  if (state.items.find(item => item.el === el)) {
    // item already in state, don't duplicate
    return;
  }

  const item = createItemFromEl(el);
  state.items.push(item);

  // ensure we're looping
  addToLoop(render);
}

export function removeItemByEl(el: HTMLElement) {
  state.items = state.items.filter(item => item.el !== el);
}

function render() {
  state.items.forEach(item => renderItem(item));
}

function getItems(root: HTMLElement): Item[] {
  const items = query("[data-card-canvas-item]", root as HTMLElement);
  return items.map(createItemFromEl);
}

function createItemFromEl(el: HTMLElement): Item {
  return {
    el,
    img: query("img", el) as HTMLImageElement[],
    caption: el.querySelector(".CardCanvasItem__caption") as HTMLElement,
    heightReferenceEl: el.parentElement.querySelector("[data-card-canvas-height-reference]") as HTMLElement,
  };
}

function renderItem(item: Item) {
  renderItemMaxHeight(item)
    .then(() => renderItemCaptionWidth(item))
    .catch(() => {});
}

function renderItemMaxHeight(item: Item) {
  return new Promise<void>((resolve, reject) => {
    fastdom.measure(() => {
      const display = getComputedStyle(item.el).getPropertyValue("display");

      // do nothing if item isn't visible
      if (display === "none") {
        return reject();
      }

      const viewportWidth = window.innerWidth;
      const refElHeight = item.heightReferenceEl.clientHeight;
      const captionHeight = item.caption ? item.caption.clientHeight : 0;
      const sizeCache = state.lastSizes.has(item) ? state.lastSizes.get(item) : { width: 0, height: 0, captionHeight: 0 };

      // prevent resizing if viewport/refEl size hasn't changed
      if (viewportWidth === sizeCache.width && refElHeight === sizeCache.height && captionHeight === sizeCache.captionHeight) {
        // size hasn't change, do nothing
        return reject();
      } else {
        sizeCache.width = viewportWidth;
        sizeCache.height = refElHeight;
        sizeCache.captionHeight = captionHeight;
        state.lastSizes.set(item, sizeCache);
      }

      const maxHeight = refElHeight - captionHeight;

      fastdom.mutate(() => {
        item.img.forEach(img => (img.style.maxHeight = viewportWidth >= 900 ? `${maxHeight}px` : null));
        resolve();
      });
    });
  });
}

function renderItemCaptionWidth(item: Item) {
  return new Promise<void>(resolve => {
    if (!item.caption) return resolve();

    function onImage(image: HTMLImageElement, rect: DOMRect): void {
      fastdom.mutate(() => {
        const scaledSize = determineScaledSize(rect, {
          width: image.naturalWidth,
          height: image.naturalHeight,
        });
        const maxWidth = Math.max(scaledSize.width, MIN_CAPTION_WIDTH);
        item.caption.style.maxWidth = `${maxWidth}px`;
        resolve();
      });
    }

    fastdom.measure(() => {
      const img = item.el.querySelector("picture img") as HTMLImageElement;

      if (!img) return;

      const imgRect = img.getBoundingClientRect();

      // Has the image loaded yet?
      if (img.naturalWidth > 0) {
        onImage(img, imgRect);
      } else {
        img.addEventListener("load", () => onImage(img, imgRect));
      }
    });
  });
}

function determineScaledSize(boundingBox: ClientRect, naturalSize: { width: number; height: number }) {
  const widthRatio = naturalSize.width / boundingBox.width;
  const heightRatio = naturalSize.height / boundingBox.height;
  const scaledRatio = Math.max(widthRatio, heightRatio);
  return {
    width: naturalSize.width / scaledRatio,
    height: naturalSize.height / scaledRatio,
  };
}
