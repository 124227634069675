export class ResponseError extends Error {
  response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}

export function checkStatus(response: Response): Response {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new ResponseError(response);
  error.response = response;
  throw error;
}

export function checkType(response: Response, contentType: string, message?: string): Response {
  const contentTypeHeader = response.headers.get("content-type");
  if (contentTypeHeader && contentTypeHeader.includes(contentType)) {
    return response;
  }

  throw new TypeError(message);
}
