import fastdom from "fastdom";

import DisplayContainer from "../display-container";
import queryAll from "../dom-helpers/query";
import initCarousel from "./Carousel";
import Lightbox from "./Lightbox";
import "./index.scss";

export default function initMediaGalleries(): void {
  const lightboxes: { [id: string]: Lightbox } = {};

  // Find any lightboxes and hoist them onto the main body
  // and initialise them as lightboxes
  queryAll("[data-media-gallery-lightbox]").forEach(element => {
    const id = element.getAttribute("data-media-gallery-lightbox");
    lightboxes[id] = new Lightbox(element);
  });

  // Find any media gallery cells and give them a click
  // that opens their lightbox
  queryAll("[data-media-gallery]").forEach(gallery => {
    const id = gallery.getAttribute("data-media-gallery");
    queryAll("[data-cell-index]", gallery).forEach(cell => {
      cell.addEventListener("click", () => onCellClick(id, cell));

      cell.addEventListener("keydown", e => {
        if (Lightbox.current) return;

        if (e.code === "Enter") {
          e.preventDefault();
          e.stopPropagation();
          onCellClick(id, cell);
        }
      });
    });
  });

  // Set up some keyboard shortcuts
  window.removeEventListener("keydown", onKeyDown);
  window.addEventListener("keydown", onKeyDown);

  /**
   * Open the gallery lightbox at a given cell
   * @param galleryId
   * @param cell
   */
  function onCellClick(galleryId: string, cell: HTMLElement): void {
    const openToIndex = parseInt(cell.getAttribute("data-cell-index"), 10);

    // Pause any gallery grid videos when the lightbox opens...
    pauseVideos();

    // ...and unpause them when it closes
    lightboxes[galleryId].open(openToIndex, () => {
      unpauseVideos();

      // Restore focus
      cell.focus();
    });
  }
}

function onKeyDown(event: KeyboardEvent): void {
  if (!Lightbox.current) return;
  // Ignore keyboard events if a video is fullscreen
  if (DisplayContainer.getHeight() === window.screen.height) return;

  switch (event.code) {
    case "Tab":
      const focusable = Lightbox.current.getFocusable();
      const first = focusable[0];
      const last = focusable[focusable.length - 1];
      // If we are wrapping around or nothing valid is focused
      if (!focusable.includes(document.activeElement as HTMLElement) || (!event.shiftKey && document.activeElement === last)) {
        event.preventDefault();
        event.stopPropagation();
        first.focus();
      } else if (event.shiftKey && document.activeElement === first) {
        event.preventDefault();
        event.stopPropagation();
        last.focus();
      }
      break;

    case "Enter":
      if (document.activeElement.className.includes("plyr")) {
        event.preventDefault();
        event.stopPropagation();
        // Enter the video (go full screen)
        (document.activeElement.querySelector('[data-plyr="fullscreen"]') as HTMLButtonElement).click();
      }
      break;

    case "ArrowRight":
      event.preventDefault();
      event.stopPropagation();
      if (!document.activeElement.className.includes("plyr")) {
        Lightbox.current.next();
      }
      break;

    case "ArrowLeft":
      event.preventDefault();
      event.stopPropagation();
      if (!document.activeElement.className.includes("plyr")) {
        Lightbox.current.prev();
      }
      break;

    case "Escape":
      event.preventDefault();
      event.stopPropagation();
      Lightbox.current.close();
      break;
  }
}

function pauseVideos() {
  queryAll("[data-media-gallery] video").forEach((video: HTMLVideoElement) => video.pause());
}

function unpauseVideos() {
  // Unpause visible videos
  queryAll("[data-media-gallery] video").forEach((video: HTMLVideoElement) => {
    fastdom.measure(() => {
      const bounds = video.getBoundingClientRect();
      if (bounds.top < DisplayContainer.getHeight() && bounds.bottom > 0) {
        video.play();
      }
    });
  });
}

//CAROUSEL

// Initialize non-center carousels
queryAll("[data-media-carousel]").forEach(gallery => {
  initCarousel(gallery, false);
});

// Initialize center carousels
queryAll("[data-media-center-carouse]").forEach(centerCarousel => {
  initCarousel(centerCarousel, true);
});
