export default function initPreventStoryScroll(navToggle: HTMLInputElement, htmlElement: HTMLHtmlElement) {
  const run = () => handleCheck(navToggle, htmlElement);
  navToggle.addEventListener("change", run);
  run();
}

export function handleCheck(navToggle: HTMLInputElement, htmlElement: HTMLHtmlElement) {
  const funcToExec = navToggle.checked ? disableStoryScroll : enableStoryScroll;
  funcToExec(htmlElement);
}

const px = (n: number) => `${n}px`;

export function disableStoryScroll(htmlElement: HTMLHtmlElement) {
  const { width, height, top } = htmlElement.getBoundingClientRect();

  Object.assign(htmlElement.style, {
    // fixed position will disable scrolling
    position: "fixed",
    // maintain 'scroll position' whilst scrolling is disabled
    // will jump to title section without this
    top: px(top),
    width: px(width),
    height: px(height),
    // force scrollbar to remain (we assume there's a scrollbar)
    overflowY: "scroll",
  });
}

export function enableStoryScroll(htmlElement: HTMLHtmlElement) {
  const previousScrollY = parseInt(htmlElement.style.top, 10);
  // prevent style properties set by `disableStoryScroll`
  Object.assign(htmlElement.style, {
    position: "",
    top: "",
    width: "",
    height: "",
    overflowY: "",
  });
  window.scrollTo(0, -previousScrollY);
}
