import fastdom from "fastdom";

export default function renderCardCanvasHeight(el: HTMLElement, items: HTMLElement[]) {
  fastdom.measure(() => {
    const viewportWidth = window.innerWidth;
    const tallestItem = items.reduce(
      (tallest, current) => (tallest.clientHeight > current.clientHeight ? tallest : current),
      items[0]
    );
    const tallestHeight = tallestItem.clientHeight;
    fastdom.mutate(() => {
      el.style.height = viewportWidth >= 900 ? `${tallestHeight}px` : null;
    });
  });
}
