import fastdom from "fastdom";

import DisplayContainer from ".";
import { getDeviceInfo } from "../device-detect/index";
import queryAll from "../dom-helpers/query";

interface IState {
  tallTOMs: Array<HTMLElement>;
}

// Hack to match the height and clipping of loooong text in TOM sections

// Watch any sections marked with a flex height and make sure they
// are tall enough to show their text children
export default function initFullHeightFlex() {
  const { isIE, isEdge } = getDeviceInfo();
  if (!isIE && !isEdge) return;

  const state: IState = {
    tallTOMs: [],
  };

  fastdom.measure(() => {
    queryAll(".DisplayContainerHeight--flex").forEach(tom => {
      state.tallTOMs.push(tom);
    });

    // Kick this off once all layouts are registered
    function onResize() {
      updateFlexHeightContainers(state);
    }
    onResize();

    window.addEventListener("resize", onResize, { passive: true });
  });
}

function updateFlexHeightContainers(state: IState) {
  state.tallTOMs.forEach(tom => {
    fastdom.measure(() => {
      const mediaElement: HTMLDivElement = tom.querySelector(".FullSize--child");

      // When in single column layout the media element should just be the height
      // of the window. Otherwise we need to match the height with the
      // containing TOM split layout element
      const basisHeight = tom.querySelector(".DisplayContainer--heightBasis").getBoundingClientRect().height + 200;
      let basisHeightStyle = basisHeight + "px";
      let mediaHeightStyle = basisHeightStyle;

      // If our TOM isn't tall enough to really 'flex' then don't resize the media
      if (basisHeight < DisplayContainer.getHeight()) {
        tom.style.setProperty("height", basisHeightStyle);
        return;
      }

      // If we are in a thin SplitLayout then adjust the media to be half
      // a screen tall
      if (tom.classList.contains("SplitLayout") && DisplayContainer.getWidth() <= 960) {
        basisHeightStyle = `calc(${basisHeight} + 50vh)`;
        mediaHeightStyle = "50vh";
      }

      fastdom.mutate(() => {
        tom.style.setProperty("height", basisHeightStyle);
        mediaElement.style.setProperty("height", mediaHeightStyle);
        queryAll(".FullSize--fixedChild", mediaElement).forEach(mediaDetailElement => {
          mediaDetailElement.style.setProperty("height", mediaHeightStyle);
        });
        mediaElement.style.setProperty("clip-path", `polygon(0 0, 0 ${mediaHeightStyle}, 100% ${mediaHeightStyle}, 100% 0)`);
      });
    });
  });
}
