import query from "../dom-helpers/query";
import renderGallery from "../gallery-renderer/gallery-renderer";

/* TODO: Refactor the object-fit module so you can add/remove elements to its state as you please */
import initObjectFit from "../object-fit";

const CLASSNAME_INITIALIZING = "GalleryRenderer--initializing";

export function initSection(section) {
  const url = section.getAttribute("data-url");
  const mode = section.getAttribute("data-mode");
  renderGallery(url, mode, html => {
    section.innerHTML = html;
    section.classList.remove(CLASSNAME_INITIALIZING);

    initObjectFit();
  });
}

export default function init() {
  const sectionsList = query("[data-galleryrenderer]");
  sectionsList.forEach(initSection);
}
