import "intersection-observer";

import "../core/globals";

/**
 * ScrollWatch v3.0, made more perfomant by using the new IntersectionObserver API
 * (polyfill included for the mumpets). This is a bit different from previous
 * versions as it only triggers at the intersection line, and not continuously
 * while the element being watched is visible.
 *
 * Usage:
 * `scrollwatch(elementToWatch, callbackToRun, { triggerPoint: TriggerPoints.Xxxx })`
 * ```
 * import scrollwatch, { TriggerPoints.On, VISIBILITY_THRESHOLD } from 'scrollwatch';
 *
 * scrollwatch(video, entry => {
 *   if (entry.intersectionRatio > VISIBILITY_THRESHOLD) {
 *     // video has just come into viewport, play it
 *     video.play();
 *   } else {
 *     // video is out of the viewport again, pause it
 *     video.pause();
 *   }
 * }, { triggerPoint: TriggerPoints.On });
 * ```
 */

enum TriggerPoints {
  Near = "nearly visible",
  On = "on visibile",
  Partial = "partially visible",
  Mostly = "mostly visible",
  Full = "fully visible",
  Lazyload = "lazyload",
  LazyloadModel = "lazyload 3D models",
  UnloadModel = "unload 3D models",
  LazyDownloadModel = "lazy download 3D models",
}

type Root = null | Element;

export { TriggerPoints };

window.Shorthand.lazyloadTriggerMargin = window.Shorthand.lazyloadTriggerMargin || "500%";
const viewportHeight = window.innerHeight || window.screen.height;

const ROOT_MARGINS = {
  [TriggerPoints.Near]: "100%",
  [TriggerPoints.On]: "0%",
  [TriggerPoints.Partial]: "-10%",
  [TriggerPoints.Mostly]: "-40% 0% -40% 0%", // Mostly visible, but only in the vertical direction (STO-4840)
  [TriggerPoints.Full]: "0px",
  [TriggerPoints.Lazyload]: window.Shorthand.lazyloadTriggerMargin,
  [TriggerPoints.LazyloadModel]: `${viewportHeight * 2}px`,
  [TriggerPoints.UnloadModel]: `${viewportHeight * 2 + 100}px`,
  [TriggerPoints.LazyDownloadModel]: `${viewportHeight * 5}px`,
};

/**
 * Override the default threshold for specific breakpoints
 */
const THRESHOLDS = {
  /**
   * Slightly higher than zero, so we can reliably do visibility detection.
   * If set to zero, sometimes visibility can be triggered exactly at zero and we
   * would assume that meant hidden. Value is picked based on precision observed
   * in Chrome. In the future we may rely on entry.isIntersecting, but it's not
   * common yet.
   */
  [TriggerPoints.Near]: [0, 0.001],
  [TriggerPoints.Lazyload]: [0, 0.001],
  [TriggerPoints.On]: [0, 0.001],
  [TriggerPoints.Mostly]: [0, 0.001],
  [TriggerPoints.Full]: 0.99,
  [TriggerPoints.LazyloadModel]: [0, 0.001],
  [TriggerPoints.LazyDownloadModel]: [0, 0.001],
  [TriggerPoints.UnloadModel]: [0, 0.001],
  // Special monkey business used by active-navitem-tracker
  [TriggerPoints.Partial]: 0,
};

interface InternalIntersectionObserver {
  instance: IntersectionObserver;
  triggerPoint: TriggerPoints;
  root: Root;
}
const intersectionObservers: InternalIntersectionObserver[] = [];
const observables: Observable[] = [];

interface Observable {
  target: Element;
  triggerPoint: string;
  callbacks: Function[];
  root: Root;
}

export function isVisible(entry: IntersectionObserverEntry) {
  return entry.intersectionRatio >= 0.00001;
}

export function getIntersectionObserver(triggerPoint: TriggerPoints, root: Root = null) {
  const foundIntersectionObserver = intersectionObservers.find(io => io.triggerPoint === triggerPoint && io.root === root);

  if (!foundIntersectionObserver) {
    const options = {
      rootMargin: ROOT_MARGINS[triggerPoint],
      threshold: THRESHOLDS[triggerPoint],
      root,
    };

    const io = new IntersectionObserver(entries => {
      onIntersection(entries, triggerPoint, root);
    }, options);

    intersectionObservers.push({ instance: io, triggerPoint, root });
    return io;
  }

  return foundIntersectionObserver.instance;
}

export function onIntersection(entries: IntersectionObserverEntry[], triggerPoint: TriggerPoints, root: Root = null) {
  entries.forEach(entry => {
    const observable = getObservable(entry.target as Element, triggerPoint, root);

    observable.callbacks.forEach(cb => cb(entry));
  });
}

export function createObservable(target: Element, triggerPoint: TriggerPoints, root: Root = null) {
  const observable: Observable = { target, triggerPoint, callbacks: [], root };
  observables.push(observable);
  return observable;
}

export function getObservable(target: Element, triggerPoint: TriggerPoints, root: Root = null) {
  return observables.find(observed => {
    return observed.target === target && observed.triggerPoint === triggerPoint && observed.root === root;
  });
}

/**
 * WARNING: Removes _all_ callbacks for the target at the provided triggerPoint
 */
interface ObserveOptions {
  root?: Element;
  triggerPoint?: TriggerPoints;
}
export function unobserve(target: Element, options: ObserveOptions = {}) {
  const triggerPoint = options.triggerPoint || TriggerPoints.On;
  const root = options.root || null;
  const io = getIntersectionObserver(triggerPoint, root);
  io.unobserve(target);
}

export default function observe(target: Element, callback: (entry: IntersectionObserverEntry) => void, options: ObserveOptions = {}) {
  const triggerPoint = options.triggerPoint || TriggerPoints.On;
  const root = options.root || null; // null defaults to viewport as per IO API
  const io = getIntersectionObserver(triggerPoint);
  let observable = getObservable(target, triggerPoint, root);
  if (!observable) {
    observable = createObservable(target, triggerPoint, root);
  }
  observable.callbacks.push(callback);
  io.observe(target);
}
