import queryAll from "../dom-helpers/query";

// Partners Photo View Tracking https://paper.dropbox.com/published/Partners-Photo-View-Tracking-LB4ssLsthfBoZfK5n9zYNbq

export function trackView(appId: string, stockMediaId: string): void {
  const viewTrackingUrl = `https://views.unsplash.com/v?app_id=${appId}&photo_id=${stockMediaId}`;
  // multiples https://views.unsplash.com/v?app_id=1923&photo_id=LBI7cgq3pbM,GhK568hd69j,jhER169j7zP,yhf12kER8b7

  fetch(viewTrackingUrl).catch(error => console.log(error));
}

export default function initUnsplash(): void {
  // handle multi ids

  queryAll("[data-unsplash-ids]").forEach(element => {
    const ids = element.getAttribute("data-unsplash-ids");
    const appId = element.getAttribute("data-unsplash-app");
    trackView(appId, ids);
  });
}
