import DisplayContainer from "../display-container";
import query from "../dom-helpers/query";

interface SocialNetwork {
  networkName: string;
  shareAction: string;
  popupWidth: number;
  popupHeight: number;
}

interface Config {
  [key: string]: SocialNetwork;
}

const CONFIG: Config = {
  facebook: {
    networkName: "Facebook",
    shareAction: "Share",
    popupWidth: 600,
    popupHeight: 420,
  },
  twitter: {
    networkName: "Twitter",
    shareAction: "Tweet",
    popupWidth: 600,
    popupHeight: 250,
  },
  linkedin: {
    networkName: "LinkedIn",
    shareAction: "Share",
    popupWidth: 600,
    popupHeight: 250,
  },
};

interface PopupOptions {
  top: number;
  left: number;
  width: number;
  height: number;
  personalbar: number;
  toolbar: number;
  scrollbars: number;
  resizable: number;
  [key: string]: number;
}

export function showPopup(url: string, config: SocialNetwork) {
  // centre the popup in the viewport
  const left = Math.round(DisplayContainer.getWidth() / 2 - config.popupWidth / 2);
  let top = 0;

  if (DisplayContainer.getHeight() > config.popupHeight) {
    top = Math.round(DisplayContainer.getHeight() / 3 - config.popupHeight / 2);
  }

  const popupOptions: PopupOptions = {
    left,
    top,
    width: config.popupWidth,
    height: config.popupHeight,
    personalbar: 0,
    toolbar: 0,
    scrollbars: 1,
    resizable: 1,
  };

  const serializedPopupOptions = Object.keys(popupOptions)
    .map(key => `${key}=${popupOptions[key]}`)
    .join(",");
  const windowName = "social-widget-" + config.networkName.replace(/\s+/, "-");
  const popup = window.open(url, windowName, serializedPopupOptions);

  if (popup) {
    popup.focus();
  } else {
    location.href = url;
  }
}

export function onButtonClick(event: Event) {
  event.preventDefault();
  const button = event.currentTarget as HTMLAnchorElement;
  const buttonType = button.getAttribute("data-social-share");
  const buttonConfig = CONFIG[buttonType];
  const popupUrl = button.href;

  showPopup(popupUrl, buttonConfig);
}

export function initButton(button: HTMLAnchorElement) {
  const buttonType = button.getAttribute("data-social-share");

  if (buttonType in CONFIG) {
    button.addEventListener("click", onButtonClick, { passive: false });
  } else {
    console.error(`Social Share button of type ${buttonType} is not supported.`);
  }
}

export default function initSocialShareButtons(selector = "[data-social-share]") {
  const buttons = query(selector);
  buttons.forEach(initButton);
}
