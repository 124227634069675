import fastdom from "fastdom";

import DisplayContainer from "../display-container";
import queryAll from "../dom-helpers/query";

export default function initFocalPoints() {
  window.addEventListener("resize", () => setFocalPoints(), { passive: true });
  setFocalPoints();
}

function setFocalPoints() {
  fastdom.measure(() => {
    const orientation = DisplayContainer.getOrientation();

    fastdom.mutate(() => {
      queryAll("[data-landscape-focal]").forEach((element: HTMLElement) => {
        const focal = element.dataset[`${orientation}Focal`];
        if (focal) {
          const target = element.tagName === "PICTURE" ? element.querySelector("img") : element;
          target?.style.setProperty("object-position", focal);
        }
      });
    });
  });
}

export function exposeInitFocalPointPicture() {
  window.Shorthand.initFocalPointPictures = initFocalPoints;
}
