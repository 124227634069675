/**
 * A transition rule specifies the speed and type of an in-transition on a particular frame. The
 * actual transition timing is determined externally (currently based on corresponding text heights).
 *
 * Note: currently the first frame never has an in-transition, as it is fully visible as soon as
 * it enters the viewport.
 */
export interface ITransitionRule {
  /* a comma-separated list of "none", "fade","up","down","left" and/or "right". */
  name: string;
  /* The transition speed, expressed as a multiple of the viewport height, ie
   * 1 == 'transition lasts for exaactly one full scroll of the viewport' */
  speed: number;
}

/**
 * Parse the transitions from the HTML attribute; The attribute represents the transitions as
 * a semi-colon-separated list of the form:
 *
 * transition ::= [speed] <transition-name>
 *
 * Note: the number of transitions should be 1 less than the number of frames, as the first
 * frame does not receive a transition.
 *
 * For the sake of consistency - surplus transitions are ignored. surplus frames are treated as
 * the default (none).
 *
 * If unspecified, all transitions are assumed to be 'none'.
 */
export function parseTransitionRules(attr: string | undefined): ITransitionRule[] {
  if (!attr) {
    return [];
  }
  const parts = attr.split(";");
  return parts.map(part => {
    const elems = part.trim().split(/\s+/);
    if (elems.length < 2) {
      return { name: elems[0] || "none", speed: 1 };
    } else {
      const speed = parseFloat(elems[0]);
      return { name: elems[1], speed: isNaN(speed) ? 1 : speed };
    }
  });
}

/**
 * The calculated transition for a single frame, after measuring all heights involved.
 *
 * Note: each frame is considered to be visible from it's transition start point up to the
 * next element's transition end point.
 */
export interface ITransition {
  /* a comma-separated list of "none", "fade","up","down","left" and/or "right". */
  name: string;
  /* The scrollPercentage at which the frame first becomes visible (ie begins transitioning in) */
  start: number;
  /* The scrollPercentage at which the frame completes transitioning in (ie is now fully visible) */
  end: number;
}

/**
 * Compute the transition start + end points for all frames in the component.
 * Note: assumes that textHeights.length === number of frames.
 *
 * @param transitionRules The defined transition rules for the component
 * @param textHeights The heights of each text block.
 * @param viewportHeight The height of the viewport in pixels
 * @param scrollHeight The total height of the scroll track, in pixels
 */
export function calculateTransitions(
  transitionRules: ITransitionRule[],
  textHeights: number[],
  viewportHeight: number,
  parentHeight: number
): ITransition[] {
  const scrollHeight = viewportHeight + parentHeight;
  const bottomPaddingOffset = viewportHeight * 0.4;
  const heightPercentage = viewportHeight / scrollHeight;
  const start = -bottomPaddingOffset / scrollHeight;
  const transitions: ITransition[] = [{ name: "none", start, end: start + heightPercentage }];

  let textHeight = viewportHeight;
  for (let idx = 0; idx < textHeights.length - 1; idx++) {
    textHeight += textHeights[idx];
    const duration = (transitionRules[idx]?.speed ?? 1) * heightPercentage;
    const start = (textHeight - bottomPaddingOffset) / scrollHeight;
    transitions.push({
      name: transitionRules[idx]?.name || "none",
      start,
      end: start + duration,
    });
  }

  return transitions;
}

export interface ILayoutAttributes {
  index: number;
  display: "none" | "block";
  opacity?: number;
  clipPath?: string;
  transitionPercentage?: number;
}
/**
 * How we pick which images to show:
 * An image is potentially visible from it's transition.start value (percent) until the
 * _end_ of the next image's transition.
 * The transition length is the scene.height (which a percentage of the layer's scroll height)
 * I.e. if the scene is 1000px tall, and the layer is 5000px then the scene
 * height is 1000/5000 = 0.2 (20%)
 * To calculate the next image's transition end, we simply get it's start value
 * and add the transition length to it.
 * There's an edge case for the first image, it needs to be shown before it's
 * transitionStart (it shouldn't actually have a transition) so that it's
 * rendered statically while the media renderer is scrolled into view.
 */
export function layoutScene(transitions: ITransition[], scrollPercentage: number): ILayoutAttributes[] {
  return transitions.map((transition, index) => {
    if (
      (index !== 0 && scrollPercentage < transition.start) ||
      (index + 1 < transitions.length && scrollPercentage > transitions[index + 1].end)
    ) {
      return { index, display: "none" };
    } else {
      /* Otherwise the item is visible (ish) - compute the transition (if any)
       * How transition percentage is calculated:
       * We have to subtract the item's transition-start value from the scrollPercentage.
       * This gives us a scroll percentage value, relative from the items transition start.
       * We then divide the relative scroll value by the transition length to calculate
       * the transition percentage.
       *
       * e.g. And item's transitionStart is 40%, the current scroll percentage is 44%
       * and the transition length is 20%. Actual values are between 0 and 1.
       * Calc relative scroll percentage: 0.44 - 0.40 = 0.04 (4%)
       * Divide by transition length: 4 / 20 = 0.2
       * Therefore item's transition percentage is 0.2 (20%).
       */
      const transitionLength = transition.end - transition.start;
      const relativeTransitionStart = scrollPercentage - transition.start;
      const transitionPercentage = Math.min(relativeTransitionStart / transitionLength, 1);

      const attributes = calculateTransitionAttributes(transition, index, transitionPercentage);

      if (attributes.opacity === 0) {
        return { display: "none", index };
      } else {
        return attributes;
      }
    }
  });
}

function calculateTransitionAttributes(transition: ITransition, index: number, transitionPercentage: number): ILayoutAttributes {
  const attributes: ILayoutAttributes = { display: "block", transitionPercentage, index, clipPath: "none", opacity: 1 };
  let clipTop = 0,
    clipRight = 0,
    clipBottom = 0,
    clipLeft = 0;
  const splitNames = transition.name.split(",");
  splitNames.forEach(name => {
    switch (name) {
      case "fade":
        attributes.opacity = transitionPercentage;
        break;
      case "up":
        clipTop = (1 - transitionPercentage) * 100;
        break;
      case "right":
        clipRight = (1 - transitionPercentage) * 100;
        break;
      case "down":
        clipBottom = (1 - transitionPercentage) * 100;
        break;
      case "left":
        clipLeft = (1 - transitionPercentage) * 100;
        break;
    }
  });
  if (clipTop || clipRight || clipBottom || clipLeft) {
    attributes.clipPath = `inset(${clipTop}% ${clipRight}% ${clipBottom}% ${clipLeft}%)`;
  }
  return attributes;
}
