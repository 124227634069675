import TinyEmitter from "tiny-emitter";

import query from "../dom-helpers/query";
import initElementLinger from "./element-linger";
import initGoogleAnalytics from "./google-analytics";
import initLinkClickTracking from "./link-click-tracking";

const emitter: TinyEmitter = new TinyEmitter();

export function logEvent(...args: string[]) {
  emitter.emit("logEvent", ...args);
}

export function logSocial(...args: string[]) {
  emitter.emit("logSocial", ...args);
}

export function subscribe(eventName: string, cb: () => void) {
  emitter.on(eventName, cb);
}

export function unsubscribe(eventName: string, cb: () => void) {
  emitter.off(eventName, cb);
}

export default function initAnalytics() {
  const lingerElements = query(".Theme-Section") as HTMLElement[];
  initGoogleAnalytics(emitter);
  initElementLinger(lingerElements, logEvent);
  initLinkClickTracking();
}
